import React from 'react';
import RouterBase from "app/routes/RouterBase";

export default class DatabasePages extends RouterBase {

    get className() {
        return 'app-wrapper';
    }

    get routes() {
        return [
            {
                path: '/:id/modify',
                asyncComponent: () => import('./modify'),
                requiredRoles: ['database-update']
            },
            {
                path: '/create',
                asyncComponent: () => import('./modify'),
                requiredRoles: ['database-create']
            },
            {
                path: '/compare',
                asyncComponent: () => import('./compare'),
                requiredRoles: ['database-read']
            },
            {
                path: '/get-list',
                asyncComponent: () => import('./get-list'),
                requiredRoles: ['database-read']
            },
            {
                path: '/:id/dashboard',
                asyncComponent: () => import('./dashboard'),
                requiredRoles: ['database-read', 'data-read']
            },
            {
                path: '/:id/migration',
                asyncComponent: () => import('./migration'),
                requiredRoles: ['advanced-data-migration']
            },
            {
                path: '/:id/import',
                asyncComponent: () => import('./import'),
                requiredRoles: ['database-read', 'data-create']
            },
            {
                path: '/:id/export',
                asyncComponent: () => import('./export'),
                requiredRoles: ['database-read', 'data-read']
            },
            {
                path: '/:id/service',
                asyncComponent: () => import('./service'),
                requiredRoles: ['database-read']
            },
            {
                path: '/:id/data-view',
                asyncComponent: () => import('./data-view'),
                requiredRoles: ['database-read']
            },
            {
                path: '/:id/report',
                asyncComponent: () => import('./report'),
                requiredRoles: ['database-read', 'data-read']
            },
            {
                path: '/:id/management-system',
                asyncComponent: () => import('./management-system'),
                requiredRoles: ['super-admin']
            },
            {
                path: '/:id/audit-log',
                asyncComponent: () => import('./audit-log'),
                requiredRoles: ['super-admin']
            }
        ]
    }
}