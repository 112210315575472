import React, {Component} from "react";
import SwitchRoutes from "appUtil/SwitchRoutes";
import asyncComponent from "appUtil/asyncComponent";
import * as _ from "lodash";

export default class RouterBase extends Component {

    constructor(props) {
        super(props);
        this.state = {
            originalRoutes: this.routes,
            location: props.location,
            routes: this.routes.map(r => {
                let route = {...r};
                if (route.asyncComponent) {
                    route.component = asyncComponent(route.asyncComponent);
                }
                return route;
            })
        }
    }

    get routes() {
        return [];
    }

    get className() {
        return '';
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {};
        if (!_.isEqual(nextProps.location, prevState.location)) {
            state.location = nextProps.location;
            state.routes = prevState.originalRoutes.map(r => {
                let route = {...r};
                if (route.asyncComponent) {
                    route.component = asyncComponent(route.asyncComponent);
                    delete route.asyncComponent;
                }
                return route;
            });
        }
        return state;

    }

    render() {
        const {routes} = this.state;
        const {match} = this.props;
        return (
            <div className={this.className}>
                <SwitchRoutes routes={routes} path={match.url}/>
            </div>
        );
    }
}