import React from 'react';
import LanguageItem from './LanguageItem';
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import AppLocale from "../../lngProvider";


const LanguageSwitcher = ({switchLanguage, handleRequestClose, currentLocale}) => {
    return Object.keys(AppLocale).filter(code => currentLocale.locale !== code).map((langCode, index) => <LanguageItem key={index} language={AppLocale[langCode]} langCode={langCode}
                                                                     handleRequestClose={handleRequestClose}
                                                                     switchLanguage={switchLanguage}/>);
};

const mapStateToProps = ({settings}) => {
    const currentLocale = settings.locale;
    return {currentLocale}
};

export default withRouter(connect(mapStateToProps)(LanguageSwitcher));