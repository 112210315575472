import React from 'react';
import RouterBase from "app/routes/RouterBase";


export default class ServicePages extends RouterBase {

    get className() {
        return 'app-wrapper';
    }

    get routes() {
        return [
            {
                path: '/:id/modify',
                asyncComponent: () => import('./modify')
            },
            {
                path: '/create',
                asyncComponent: () => import('./modify')
            },
            {
                path: '/dashboard',
                asyncComponent: () => import('./dashboard')
            }
        ]
    }
}