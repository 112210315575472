export default class EventBus {
    private listeners: Object;

    public addListener(eventName: string, callback: () => void) {
        if(!this.listeners) {
            this.listeners = {};
        }
        if(!this.listeners[eventName]) {
            this.listeners[eventName] = [];
        }
        this.listeners[eventName].push(callback);
    }

    public removeListener(eventName, callback) {
        if(!this.listeners || !this.listeners[eventName]) {
            return
        }
        this.listeners[eventName] = this.listeners[eventName].filter(c => c !== callback);
    }

    public emit(eventName, data?) {
        if (!this.listeners || !this.listeners[eventName]) {
            return
        }
        for (const callback of this.listeners[eventName]) {
            callback(data);
        }
    }
}