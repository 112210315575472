import '../js/define';

try {
    if(WITHOUT_APP_TYPE) {
        try {
            require('./indexWithoutApp');
        }
        catch (e) {
            console.error(e);
        }
    }
    else {
        require('./indexApp');
    }
}
catch (e) {
    require('./indexApp');
}
