import BaseService from "./BaseService";
import {stringify} from "query-string";

export default class UserService extends BaseService {

    public getUser(id: number) {
        return this.client.get(`/api/v1/users/${id}`);
    }

    public getUsers() {
        return this.client.get('/api/v1/users');
    }

    public getUserRoles(userId: number, genericUserOnRole?: number | string) {
        let genericPk = `${userId}`;
        if (genericUserOnRole) {
            genericPk += `.${genericUserOnRole}`;
        }
        return this.client.get(`/api/v1/user/${genericPk}/role`);
    }

    public getRoles() {
        return this.client.get('/api/v1/roles');
    }

    public addRole(userId: number, role: number | string, databaseCatalogId: number = null, databaseCatalogGroupId: number = null) {
        let data = {};
        if (typeof (role) === "string") {
            data['role_name'] = role;
        } else {
            data['role_id'] = role;
        }
        if (databaseCatalogId) {
            data['database_catalog_id'] = databaseCatalogId;
        }
        if (databaseCatalogGroupId) {
            data['database_catalog_group_id'] = databaseCatalogGroupId;
        }
        return this.client.post(`/api/v1/user/${userId}/role`, data);
    }

    public deleteRole(userId: number, roleId: number, databaseCatalogId: number = null, databaseCatalogGroupId: number = null) {
        let url = `/api/v1/user/${userId}/role/${roleId}`;
        let q = {}
        if (databaseCatalogId) {
            q['database_catalog_id'] = databaseCatalogId;
        }
        if (databaseCatalogGroupId) {
            q['database_catalog_group_id'] = databaseCatalogGroupId;
        }
        url += '?' + stringify(q)
        return this.client.delete(url);
    }

    public addServicePermission(userId: number, serviceId: number) {
        return this.client.post(`/api/v1/user/${userId}/service-permission`, {service_id: serviceId});
    }

    public deleteServicePermission(userId: number, serviceId: number) {
        return this.client.delete(`/api/v1/user/${userId}/service-permission/${serviceId}`);
    }

    public setDbManagementField(databaseId: number, userId: number, fieldId: number, data: any) {
        return this.client.post(`/api/v1/database/${databaseId}/user/${userId}/management-system/${fieldId}/field`, data);
    }

    public setDbManagement(databaseId: number, userId: number, data: any) {
        return this.client.post(`/api/v1/database/${databaseId}/user/${userId}/management-system`, data);
    }

    public searchUser(username: string) {
        return this.client.post(`/api/v1/user/search`, {username});
    }

    public addUser(username: string, authId?: number) {
        return this.client.post(`/api/v1/user/add`, {username, auth_id: authId ? authId : 0});
    }

    public setDbManagementRecordFilter(databaseCatalogId: number, userId: number, filterId: number, data: any) {
        return this.client.post(`/api/v1/database-catalog/${databaseCatalogId}/user/${userId}/management-system/${filterId}/filter`, data);
    }

    public getAdvancedDatabasePermissions(userId: number, genericUserOnRole?: number | string, databaseId = 0) {
        let genericPk = `${userId}`;
        if (genericUserOnRole) {
            genericPk += `.${genericUserOnRole}`;
        }
        return this.client.get(`/api/v1/database/${databaseId}/user/${genericPk}/management-system`);
    }
}