import AuthService from "../ts/services/AuthService";
import apiClient from "./data/apiClient";
import {stringify} from "query-string";

if (WITHOUT_APP_TYPE === 'unauthorized') {
    const token = localStorage.getItem('user_id');
    const authService = new AuthService(apiClient);
    if (token && token.trim() !== '') {
        authService.setAuthorization('Bearer ' + token);
        let xhttp = new XMLHttpRequest();
        xhttp.responseType = "blob";
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                let iframe = window.document.createElement('iframe');
                iframe.src = URL.createObjectURL(xhttp.response);
                iframe.style.width = "100%";
                iframe.style.height = "100%";
                iframe.style.border = "none";
                window.document.body.style.height = "100%";
                window.document.body.style.margin = "0";
                window.document.body.appendChild(iframe);
                window.document.body.parentElement.style.height = "100%";
            } else if (this.readyState === 4 && this.status === 401) {
                authService.getLoginUrl(window.location.href).then(response => {
                    window.location = response.data.url;
                });
            }
        };
        xhttp.open("GET", window.location.href, true);
        xhttp.setRequestHeader('Authorization', 'Bearer ' + token);
        xhttp.send();
    } else {
        let query = {
            backUrl: window.location.href
        };
        window.location = `${window.location.origin}/#/signin?${stringify(query)}`;
    }
}