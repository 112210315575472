import BaseService from "./BaseService";
import {stringify} from "query-string";

export default class UploadFileService extends BaseService {
    public createFile(filename: string, mimeType: string, size: number) {
        return this.client.post('/api/v1/upload-file', {
            file_name: filename,
            mime_type: mimeType,
            size
        });
    }

    public putChunkedContent(fileId: string, index: number, content) {
        return this.client.put(`/api/v1/upload-file/${fileId}?` + stringify({index}), content, {
            'Content-Type': 'application/octet-stream'
        });
    }
}