import BaseService from "./BaseService";

export default class GenericService extends BaseService {
    public getSettings() {
        return this.client.get('/api/v1/settings');
    }

    public modifySettings(data) {
        return this.client.post('/api/v1/settings/modify', data);
    }
}