import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import IntlMessages from 'appUtil/IntlMessages';
import {injectIntl} from "react-intl";
import CustomScrollbars from "appUtil/CustomScrollbars";
import connect from "react-redux/es/connect/connect";
import {Button, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import Helpers from "../../../ts/utils/Helpers";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Tooltip from "components/Tooltip";
import DatabaseGroupModify from "components/DatabaseGroup/DatabaseGroupModify";
import cn from "classnames";
import FileSaver from 'file-saver';
import Notification from "appUtil/Notification";
import {NotificationManager} from "react-notifications";
import DatabaseVersionLabel from "../../../tsx/components/database/DatabaseVersionLabel";
import SplitPane from "components/SplitPane";

class SidenavContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            databaseList: [...this.props.databaseList.sort(Helpers.arrayCompareValues('order', 'ASC'))],
            databaseGroups: [...this.props.databaseGroups.sort(Helpers.arrayCompareValues('order', 'ASC'))],
            selectedGroup: null,
            exportingDatabasesGroup: false,
            exportingDatabases: false,
            searchText: ''
        };
        this.handleDragEnd = this.handleDragEnd.bind(this);
        this.createDatabaseGroup = this.createDatabaseGroup.bind(this);
        this.onChangeDatabaseGroup = this.onChangeDatabaseGroup.bind(this);
        this.saveDatabaseGroup = this.saveDatabaseGroup.bind(this);
        this.closeCollapsedNav = this.closeCollapsedNav.bind(this);
        this.updatedDatabase = this.updatedDatabase.bind(this);
        this.exportDatabases = this.exportDatabases.bind(this);
        this.importDatabases = this.importDatabases.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.canReadDatabase = Helpers.userHasDatabaseCatalogPermission(this.props.authUser, 'database-read', null);
        this.canCreateDatabase = Helpers.userHasDatabaseCatalogPermission(this.props.authUser, 'database-create', null);
        this.canUpdateDatabase = Helpers.userHasDatabaseCatalogPermission(this.props.authUser, 'database-update', null);
        this.superAdmin = Helpers.userHasDatabaseCatalogPermission(this.props.authUser, 'super-admin', null);
        this.uploadedFileForImport = null;
    }

    updatedDatabase() {
        this.setState({
            databaseList: [...this.props.databaseList.sort(Helpers.arrayCompareValues('order', 'ASC'))]
        });
        this.refresh();
    }

    componentDidMount() {
        this.importUploadFileInput = document.createElement('input');
        this.importUploadFileInput.setAttribute('type', 'file');
        this.importUploadFileInput.setAttribute('accept', '.json');
        this.importUploadFileInput.onchange = (event) => {
            if (event.target.files.length > 0) {
                const file = event.target.files[0];
                if (!Helpers.checkUploadFileSizeLimit(file)) {
                    Notification.uploadFileLimitError(file.size);
                    return;
                }
                let newFile = {
                    type: file.type,
                    filename: file.name
                };
                let reader = new FileReader();
                reader.onload = (e) => {
                    newFile.url = e.target.result;
                    if (this.uploadedFileForImport) {
                        this.uploadedFileForImport(newFile);
                    }
                };
                reader.readAsDataURL(file);
            }
        }
        if (this.props.renderGlobalButtons) {
            this.props.renderGlobalButtons(this.renderButtons());
        }


        this.props.databaseEventBus.addListener('changed', this.updatedDatabase);
        let selectedDatabaseCatalog = null;
        if (this.props.location.pathname) {
            const matched = /^\/app\/database\/(\d+)\//.exec(this.props.location.pathname);
            if (matched) {
                const databaseId = parseInt(matched[1]);
                selectedDatabaseCatalog = this.state.databaseList.filter(cd => cd.databases.some(d => d.id === databaseId)).first;

            }
        }
        let selectedGroup = null;
        if (selectedDatabaseCatalog && selectedDatabaseCatalog.group_id) {
            selectedGroup = this.state.databaseGroups.filter(g => g.id === selectedDatabaseCatalog.group_id).first;
        }
        if (selectedGroup) {
            this.setState({selectedGroup});
            if (this.props.onOpenDatabaseGroup) {
                this.props.onOpenDatabaseGroup();
            }
        } else {
            if (this.props.onCloseDatabaseGroup) {
                this.props.onCloseDatabaseGroup();
            }
        }
        this.refresh();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.databaseGroups !== this.state.databaseGroups) {
            this.refresh();
        }
    }

    componentWillUnmount() {
        this.props.databaseEventBus.removeListener('changed', this.updatedDatabase);
    }

    refresh() {
        const {history} = this.props;
        const that = this;
        const pathname = `#${history.location.pathname}`;// get current path

        //const subMenuLi = document.querySelectorAll('.sub-menu > li');
        // for (let i = 0; i < subMenuLi.length; i++) {
        //     subMenuLi[i].onclick = function (event) {
        //         event.stopPropagation();
        //     }
        // }

        // const menuLi = this.containerDiv.getElementsByClassName('menu');
        // for (let i = 0; i < menuLi.length; i++) {
        //     if (menuLi[i].firstChild) {
        //         menuLi[i].firstChild.onclick = function (event) {
        //             // for (let j = 0; j < menuLi.length; j++) {
        //             //     const parentLi = that.closest(this, 'li');
        //             //     if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
        //             //         menuLi[j].classList.remove('open')
        //             //     }
        //             // }
        //             if (!event.target.classList.contains('group-config-icon') && !event.target.classList.contains('non-group-database-items') && !this.parentElement.classList.contains('non-group-database-items') ) {
        //                 console.log(event);
        //                 this.parentElement.classList.toggle('open');
        //             }
        //             //event.stopPropagation();
        //         }
        //     }
        // }
        // const activeLi = this.containerDiv.querySelector('a[href="' + pathname + '"]');// select current a element
        // try {
        //     const activeNav = this.closest(activeLi, 'ul'); // select closest ul
        //     if (activeNav.classList.contains('sub-menu')) {
        //         this.closest(activeNav, 'li').classList.add('open');
        //     } else {
        //         this.closest(activeLi, 'li').classList.add('open');
        //     }
        // } catch (error) {
        //
        // }
    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] == 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }

        return null;
    }

    goToDatabaseDiagram(group, e) {
        e.preventDefault();
        e.stopPropagation();
        this.closeCollapsedNav();
        this.props.history.push(`/app/diagram/database?groupId=${group.id}`);
    }

    onClickDatabaseGroup(group, e) {
        e.preventDefault();
        const selectedGroup = this.state.selectedGroup === group ? null : group;
        this.setState({
            selectedGroup
        });
        if (selectedGroup && this.props.onOpenDatabaseGroup) {
            this.props.onOpenDatabaseGroup();
        } else if (!selectedGroup && this.props.onCloseDatabaseGroup) {
            this.props.onCloseDatabaseGroup();
        }
    }

    renderGroupDatabase(groupDatabase, star = false) {
        const {selectedGroup} = this.state;
        const databaseGroups = this.superAdmin ? this.state.databaseGroups : this.state.databaseGroups.filter(g => groupDatabase.hasOwnProperty(g.id));
        let tmp = databaseGroups.map((group, groupIndex) => (
            <Draggable key={`group-${groupIndex}`} index={groupIndex} draggableId={`db-group-${groupIndex}`}>
                {(draggableProvided, draggableSnapshot) => {
                    return <li className={cn("menu", {open: group === selectedGroup})} ref={draggableProvided.innerRef}
                               {...draggableProvided.draggableProps}
                               {...draggableProvided.dragHandleProps}>
                        <Droppable droppableId={`db-group-${groupIndex}-${tmp.length}-t`} type={'database-item'}
                                   isCombineEnabled={true}>
                            {(droppableProvided, droppableSnapshot2) => (
                                <a role="button" href="#"
                                   className={cn("database-group-link", {'dragging-over': droppableSnapshot2.isDraggingOver}, {"group-open": group === selectedGroup})}
                                   ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}
                                   onClick={this.onClickDatabaseGroup.bind(this, group)} style={{"--group-color": group.color_hex}}>
                                    <span className="db-list-group-name">{group.name}</span>
                                    <i className={cn("far", {"fa-angle-left": group === selectedGroup}, {"fa-angle-right": group !== selectedGroup})} />
                                </a>
                            )}
                        </Droppable>
                    </li>
                }}
            </Draggable>
        ));
        tmp.push(<Droppable key={tmp.length} droppableId={`db-group--1-${tmp.length}`} type={'database-item'}>
            {(droppableProvided, droppableSnapshot) => {
                return <li className="menu no-arrow non-group-database-items">
                    <ul ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                        {groupDatabase[-1].map((databaseCatalog, databaseCatalogIndex) => <li key={databaseCatalogIndex}
                                                                                              className="menu no-arrow"
                        >
                            {this.renderDatabaseMenuItem(databaseCatalog, star)}
                        </li>)}
                        <li className="menu no-arrow">
                            {droppableProvided.placeholder}
                        </li>
                    </ul>
                </li>
            }}
        </Droppable>)
        return tmp;
    }

    handleDragEnd(result) {
        if (!this.superAdmin || !result.destination || result.destination.droppableId === 'db-groups' && result.destination.index === result.source.index) {
            return false;
        }
        if (result.type === 'database-group') {
            let databaseGroups = [...this.state.databaseGroups];
            const [moved] = databaseGroups.splice(result.source.index, 1);
            databaseGroups.splice(result.destination.index, 0, moved);
            for (let i = 0; i < databaseGroups.length; i++) {
                databaseGroups[i].order = i;
            }
            this.setState({
                databaseGroups
            });
            this.props.registryService.moveDatabaseGroupOrder(moved.id, result.destination.index).then(response => {
            });
            this.props.databaseEventBus.emit('changed');
            return true
        } else {
            let databaseList = [...this.state.databaseList];
            let groupId = null;
            if (!result.destination.droppableId.startsWith('db-group--1')) {
                const matched = /^db-group-(\d+)/.exec(result.destination.droppableId);
                if (matched) {
                    groupId = parseInt(this.state.databaseGroups[matched[1]].id);
                }
            }

            const [moved] = databaseList.splice(result.source.index, 1);
            const isGroupChanged = moved.group_id !== groupId;
            moved.group_id = groupId;
            databaseList.splice(result.destination.index, 0, moved);
            for (let i = 0; i < databaseList.length; i++) {
                databaseList[i].order = i;
            }
            this.setState({
                databaseList
            });
            this.props.registryService.moveDatabaseCatalogOrder(moved.id, result.destination.index, moved.group_id).then(response => {
            });
            this.props.databaseEventBus.emit('changed');
            if (isGroupChanged) {
                this.props.databaseEventBus.emit('group-changed');
            }
            return true;
        }
    }

    createDatabaseGroup(e) {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.focus();
        e.currentTarget.blur();
        this.databaseGroupData = null;
        this.props.modalService.show(
            <ModalHeader><IntlMessages id="database.group.create"/></ModalHeader>,
            <ModalBody>
                <DatabaseGroupModify onChange={this.onChangeDatabaseGroup}/>
            </ModalBody>, <ModalFooter>
                <button className="system-button minimal size-s"
                        onClick={this.props.modalService.close}><IntlMessages id="common.cancel"/></button>
                <button style={{textTransform: "initial"}} className="system-button size-s"
                        onClick={this.saveDatabaseGroup}><IntlMessages id="common.save"/></button>
            </ModalFooter>);
    }

    onChangeDatabaseGroup(data) {
        this.databaseGroupData = data;
    }

    editDatabaseGroup(databaseGroup, e) {
        e.preventDefault();
        e.stopPropagation();
        this.databaseGroupData = null;
        this.props.modalService.show(
            <ModalHeader>
                <IntlMessages id="database.group.edit"/>
                <i className="far fa-times pointer" onClick={this.props.modalService.close}/>
            </ModalHeader>,
            <ModalBody>
                <DatabaseGroupModify data={databaseGroup} onChange={this.onChangeDatabaseGroup}/>
            </ModalBody>,
            <ModalFooter>
                <button className="system-button size-s outline color-error icon-text-align mr-auto">
                    <i className="far fa-trash-alt button-icon"
                    onClick={this.deleteDatabaseGroup.bind(this, databaseGroup)} />
                    <IntlMessages id="database.group.delete"/>
                </button>
                <button className="system-button size-s"
                        onClick={this.saveDatabaseGroup}><IntlMessages id="common.save"/></button>
            </ModalFooter>);
    }

    deleteDatabaseGroup(databaseGroup) {
        this.props.modalService.close();
        this.props.registryService.deleteDatabaseGroup(databaseGroup.id).then(response => {
            this.props.databaseList.filter(d => d.group_id === databaseGroup.id).forEach(d => d.group_id = null);
            this.props.databaseGroups.splice(this.props.databaseGroups.indexOf(databaseGroup), 1);
            let databaseGroups = this.state.databaseGroups.filter(g => g.id !== databaseGroup.id);
            this.setState({databaseGroups});
        });
    }

    saveDatabaseGroup() {
        this.props.modalService.close();
        if (!this.databaseGroupData) {
            return;
        }
        this.props.registryService.modifyDatabaseGroup(this.databaseGroupData).then(response => {
            let {databaseGroups} = this.state;
            if (response.status === 201) {
                databaseGroups.push(response.data);
                this.props.databaseGroups.push(response.data);
            } else {
                const index = databaseGroups.indexOf(databaseGroups.filter(g => g.id === response.data.id).first);
                databaseGroups[index] = response.data;
                this.props.databaseGroups[index] = response.data;
            }
            this.props.databaseEventBus.emit('database-group-updated');
            this.setState({databaseGroups: [...databaseGroups]});
        }).catch((reason) => NotificationManager.error(Helpers.getMessageException(reason),
            <IntlMessages
                id="database.group"/>));
    }

    render() {
        const groupDatabase = Helpers.makeGroupDatabase(this.state.databaseList);
        if (!groupDatabase[-1]) {
            groupDatabase[-1] = [];
        }
        return (
            <div className="flow-root sidebar-menu-content-container">
                <ul className="nav-menu action-buttons">
                    {this.canCreateDatabase && <li className="menu no-arrow">
                        <NavLink className="system-button size-s minimal grayscale icon-button add-db-button"
                            to="/app/database/create" role="link"
                            onClick={this.closeCollapsedNav}>
                            <Tooltip placement="top"><IntlMessages id="sidebar.databases.create"/></Tooltip>
                            <i className="far fa-database button-icon add-db" />
                        </NavLink>
                    </li>}
                    {this.canCreateDatabase && <li className="menu no-arrow">
                        <button onClick={this.createDatabaseGroup}
                            className="system-button size-s minimal grayscale icon-button">
                            <Tooltip placement="top"><IntlMessages
                                id="sidebar.databases.group.create"/></Tooltip>
                            <i className="far fa-folder-plus button-icon" />
                        </button>
                    </li>}
                    {this.canReadDatabase && this.state.databaseList.length > 0 && <li className="menu no-arrow">
                        <NavLink to="/app/database/compare" onClick={this.closeCollapsedNav}
                            className="system-button size-s minimal grayscale icon-button">
                            <Tooltip placement="top"><IntlMessages id="sidebar.databases.compare"/></Tooltip>
                            <i className="far fa-code-compare button-icon" />
                        </NavLink>
                    </li>}
                    {this.canReadDatabase && this.state.databaseList.length > 0 && <li className="menu no-arrow">
                        <NavLink to="/app/diagram/database" onClick={this.closeCollapsedNav}
                            className="system-button size-s minimal grayscale icon-button">
                            <Tooltip placement="top"><IntlMessages id="sidebar.databases.diagram"/></Tooltip>
                            <i className="far fa-diagram-project button-icon" />
                        </NavLink>
                    </li>}
                    {this.canCreateDatabase && <li className="menu no-arrow">
                        <button onClick={this.importDatabases}
                            className="system-button size-s minimal grayscale icon-button">
                            <Tooltip placement="top"><IntlMessages
                                id="sidebar.databases.import"/></Tooltip>
                            <i className="far fa-file-import button-icon"/>
                        </button>
                    </li>}
                    {this.canReadDatabase && this.state.databaseList.length > 0 && <li className="menu no-arrow">
                        <button onClick={this.exportDatabases}
                            className="system-button size-s minimal grayscale icon-button">
                            <Tooltip placement="top"><IntlMessages
                                id="sidebar.databases.export"/></Tooltip>
                            <i className="far fa-file-export button-icon"/>
                        </button>
                    </li>}
                </ul>
                <div className="drawer-dbs-groups" ref={d => this.containerDiv = d}>
                    <DragDropContext onDragEnd={this.handleDragEnd}>
                        <SplitPane split="vertical" defaultSize="50%">
                            {this.renderFirstColumn(groupDatabase)}
                            {this.renderSecondColumn(groupDatabase)}
                        </SplitPane>
                    </DragDropContext>
                </div>
            </div>
        );
    }

    async exportDatabases() {
        if (this.state.exportingDatabases) {
            return;
        }
        this.setState({exportingDatabases: true});
        const databaseCatalogs = this.state.databaseList.filter(d => !d.group_id || d.group_id === -1);
        let databases = [];
        for (const databaseCatalog of databaseCatalogs) {
            const databaseData = await this.formatDatabaseForExport(databaseCatalog);
            if (databaseData) {
                databases.push(databaseData);
            }
        }
        const data = {
            databases
        };
        let blob = new Blob([JSON.stringify(data)], {type: 'application/json;charset=utf-8'});
        FileSaver.saveAs(blob, `databases.json`);
        this.setState({exportingDatabases: false});
    }

    importDatabases() {
        this.uploadedFileForImport = async file => {
            let originalDatabase = {};
            let data = null;
            try {
                const content = file.url.split(',')[1];
                data = JSON.parse(decodeURIComponent(atob(content)));
            } catch (e) {
                Notification.error(<IntlMessages id="database.import.error"/>, <IntlMessages
                    id="database.import.file.invalid"/>)
                return;
            }
            if (!Helpers.validateImportDatabase(data)) {
                Notification.error(<IntlMessages id="database.import.error"/>, <IntlMessages
                    id="database.import.file.invalid"/>)
                return;
            }
            if (Array.isArray(data.groups)) {
                for (const group of data.groups) {
                    const oldGroup = this.props.databaseGroups.find(g => g.name === group.name);
                    if (oldGroup) {
                        group.id = oldGroup.id;
                    }
                    await this.props.registryService.modifyDatabaseGroup(group).then(response => {
                        let {databaseGroups} = this.state;
                        if (response.status === 201) {
                            databaseGroups.push(response.data);
                            this.props.databaseGroups.push(response.data);
                        } else {
                            const index = databaseGroups.indexOf(databaseGroups.filter(g => g.id === response.data.id).first);
                            databaseGroups[index] = response.data;
                            this.props.databaseGroups[index] = response.data;
                        }
                        group.id = response.data.id;
                        this.props.databaseEventBus.emit('database-group-updated');
                        this.setState({databaseGroups: [...databaseGroups]});
                    });

                    if (!group.id || !Array.isArray(group.databases)) {
                        continue;
                    }
                    for (const databaseData of group.databases) {
                        databaseData.group_id = group.id;
                        originalDatabase = {...originalDatabase, ...this.checkDatabaseForAgainModify(databaseData)};
                        await this.createDatabase(databaseData)
                    }
                }
            }
            if (Array.isArray(data.databases)) {
                for (const databaseData of data.databases) {
                    originalDatabase = {...originalDatabase, ...this.checkDatabaseForAgainModify(databaseData)};
                    await this.createDatabase(databaseData)
                }
            }
            await this.modifyDatabaseForOriginalData(originalDatabase);
            this.setState({databaseList: [...this.props.databaseList.sort(Helpers.arrayCompareValues('order', 'ASC'))]});
            NotificationManager.success(<IntlMessages id="database.imported"/>, <IntlMessages id="database"/>, 3000);
            this.props.databaseEventBus.emit('changed');
        }
        this.importUploadFileInput.click();
    }

    renderFirstColumn(groupDatabase) {
        return (
            <CustomScrollbars className="scrollbar sidebar-draggable-area"
                              style={{height: 'calc(100vh - 125px)'}}>
                <div className="db-lists-search">
                    <div className="system-input-container size-s value-m">
                        <i className="far fa-search input-icon" />
                        <input value={this.state.searchText} type="text"
                            placeholder={this.props.intl.formatMessage({id: 'sidebar.search.placeholder'})}
                            onChange={this.onChangeSearch} />
                    </div>
                </div>
                <Droppable droppableId={`db-groups`} type={'database-group'}>
                    {(droppableProvided, droppableSnapshot) => (
                        <ul className="nav-menu db-list-menu"
                            ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                            {this.renderGroupDatabase(groupDatabase)}
                            {droppableProvided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </CustomScrollbars>
        )
    }

    onChangeSearch(e) {
        const searchText = e.target.value.trim();
        let databaseGroups = [...this.props.databaseGroups.sort(Helpers.arrayCompareValues('order', 'ASC'))];
        let databaseList = [...this.props.databaseList.sort(Helpers.arrayCompareValues('order', 'ASC'))];
        if (searchText !== '') {
            databaseList = databaseList.filter(cd => cd.code.includes(searchText) || cd.name.includes(searchText));
            databaseGroups = databaseGroups.filter(g => databaseList.some(dc => dc.group_id === g.id));
        }
        this.setState({
            searchText,
            databaseList,
            databaseGroups
        });
    }

    renderSecondColumn(groupDatabase) {
        const {selectedGroup} = this.state;
        if (!selectedGroup) {
            return <div/>;
        }
        const groupIndex = this.state.databaseGroups.indexOf(selectedGroup);
        return (
            <CustomScrollbars className="scrollbar sidebar-draggable-area"
                              style={{height: 'calc(100vh - 125px)'}}>
                <div className="db-group-options">
                    {this.canUpdateDatabase &&
                        <button className="system-button size-s minimal grayscale icon-button" onClick={this.editDatabaseGroup.bind(this, selectedGroup)}>
                            <Tooltip placement="top"><IntlMessages id="database.group.edit"/></Tooltip>
                            <i className="far fa-folder button-icon"/>
                        </button>}
                    {this.canReadDatabase && groupDatabase.hasOwnProperty(selectedGroup.id) && groupDatabase[selectedGroup.id].length > 1 &&
                        <button className="system-button size-s minimal grayscale icon-button"
                              onClick={this.goToDatabaseDiagram.bind(this, selectedGroup)}>
                            <Tooltip placement="top"><IntlMessages id="sidebar.databases.diagram"/></Tooltip>
                            <i className="far fa-project-diagram button-icon"/>
                        </button>}
                    {this.canCreateDatabase &&
                        <button className="system-button size-s minimal grayscale icon-button"
                              onClick={this.importDatabaseGroup.bind(this, selectedGroup)}>
                            <Tooltip placement="top"><IntlMessages id="sidebar.databases.import"/></Tooltip>
                            <i className="far fa-file-import button-icon"/>
                        </button>}
                    {this.canReadDatabase &&
                        <button className="system-button size-s minimal grayscale icon-button"
                              onClick={this.exportDatabaseGroup.bind(this, selectedGroup)}>
                            <Tooltip placement="top"><IntlMessages id="sidebar.databases.export"/></Tooltip>
                            <i className="far fa-file-export button-icon"/>
                        </button>}
                </div>
                <Droppable droppableId={`db-group-${groupIndex}-0`} type={'database-item'}>
                    {(droppableProvided, droppableSnapshot) => (
                        <ul className="nav-menu db-list-menu db-list-submenu"
                            ref={droppableProvided.innerRef}
                            {...droppableProvided.droppableProps}>
                            {groupDatabase.hasOwnProperty(selectedGroup.id) && groupDatabase[selectedGroup.id].map(((databaseCatalog, databaseCatalogIndex) => (
                                <li key={databaseCatalogIndex} className="menu no-arrow">
                                    {this.renderDatabaseMenuItem(databaseCatalog)}
                                </li>
                            )))}
                            {droppableProvided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </CustomScrollbars>
        )
    }

    async exportDatabaseGroup(databaseGroup) {
        if (this.state.exportingDatabasesGroup) {
            return;
        }
        let groupData = {
            name: databaseGroup.name,
            databases: []
        }
        if (databaseGroup.has_logo) {
            const logoResponse = await this.props.registryService.getDatabaseGroupLogo(databaseGroup.id);
            groupData.logo = {
                filename: logoResponse.data.filename,
                type: logoResponse.data.mime_type,
                url: `data:${logoResponse.data.mime_type};base64,${logoResponse.data.content}`
            };
        }
        this.setState({exportingDatabasesGroup: true});
        const databaseCatalogs = this.state.databaseList.filter(d => d.group_id === databaseGroup.id);
        for (const databaseCatalog of databaseCatalogs) {
            const databaseData = await this.formatDatabaseForExport(databaseCatalog);
            if (databaseData) {
                groupData.databases.push(databaseData);
            }
        }
        const data = {
            groups: [
                groupData
            ]
        };
        let blob = new Blob([JSON.stringify(data)], {type: 'application/json;charset=utf-8'});
        FileSaver.saveAs(blob, `${databaseGroup.name}-databases.json`);
        this.setState({exportingDatabasesGroup: false});
    }

    async formatDatabaseForExport(databaseCatalog) {
        const database = databaseCatalog.databases.first;
        if (!database) {
            return null;
        }
        const databaseData = {
            catalog_name: database.catalog.name,
            code: database.catalog.code,
            description: database.description || '',
            fields_uniques: database.fields_uniques,
            institution: database.institution || '',
            isDraft: database.is_draft,
            schema: Helpers.deepCopy(database.schema),
            schemaFlags: database.schema_flags,
            schemaTags: database.schema_tags,
        }
        const foreignKeyFields = Helpers.getForeignKeyFieldsFromSchema(databaseData.schema);
        for (const field of foreignKeyFields) {
            const removeFk = [];
            for (const foreignKey of field.schema.foreignKeys) {
                const catalogId = parseInt(foreignKey.databaseKey);
                const databaseCatalog = this.props.databaseList.find(c => c.id === catalogId);
                if (!databaseCatalog) {
                    removeFk.push(foreignKey);
                }
                foreignKey.databaseKey = databaseCatalog.code;
            }
            for (const fk of removeFk) {
                const index = field.schema.foreignKeys.indexOf(fk);
                field.schema.foreignKeys.splice(index, 1);
            }
            if (field.schema.foreignKeys.length === 0) {
                delete field.schema.foreignKeys;
            }
        }
        if (database.has_logo) {
            const logoResponse = await this.props.registryService.getDatabaseLogo(database.id);
            databaseData.logo = {
                filename: logoResponse.data.file_name,
                type: logoResponse.data.mime_type,
                url: `data:${logoResponse.data.mime_type};base64,${logoResponse.data.content}`
            }
        }
        return databaseData;
    }

    importDatabaseGroup(databaseGroup) {
        this.uploadedFileForImport = async file => {
            let originalDatabase = {};
            let data = null;
            try {
                const content = file.url.split(',')[1];
                data = JSON.parse(decodeURIComponent(atob(content)));
            } catch (e) {
                Notification.error(<IntlMessages id="database.import.error"/>, <IntlMessages
                    id="database.import.file.invalid"/>)
                return;
            }
            if (!Helpers.validateImportDatabase(data)) {
                Notification.error(<IntlMessages id="database.import.error"/>, <IntlMessages
                    id="database.import.file.invalid"/>)
                return;
            }
            if (Array.isArray(data.groups)) {
                for (const group of data.groups) {
                    if (!Array.isArray(group.databases)) {
                        continue;
                    }
                    for (const databaseData of group.databases) {
                        databaseData.group_id = databaseGroup.id;
                        originalDatabase = {...originalDatabase, ...this.checkDatabaseForAgainModify(databaseData)};
                        await this.createDatabase(databaseData)
                    }
                }
            }
            if (Array.isArray(data.databases)) {
                for (const databaseData of data.databases) {
                    databaseData.group_id = databaseGroup.id;
                    originalDatabase = {...originalDatabase, ...this.checkDatabaseForAgainModify(databaseData)};
                    await this.createDatabase(databaseData)
                }
            }
            await this.modifyDatabaseForOriginalData(originalDatabase);
            this.setState({databaseList: [...this.props.databaseList.sort(Helpers.arrayCompareValues('order', 'ASC'))]});
            NotificationManager.success(<IntlMessages id="database.imported"/>, <IntlMessages id="database"/>, 3000);
            this.props.databaseEventBus.emit('changed');
        }
        this.importUploadFileInput.click();
    }

    async modifyDatabaseForOriginalData(originalDatabase) {
        if (Object.keys(originalDatabase).length > 0) {
            for (const catalogCode of Object.keys(originalDatabase)) {
                const databaseCatalog = this.props.databaseList.find(c => c.code === catalogCode);
                if (databaseCatalog && databaseCatalog.databases.length === 1 && databaseCatalog.databases[0].is_draft) {
                    const database = databaseCatalog.databases.first;
                    const databaseData = {
                        id: database.id,
                        isDraft: originalDatabase[catalogCode].isDraft,
                        schema: Helpers.deepCopy(database.schema),
                        code: catalogCode,
                        catalog_name: databaseCatalog.name
                    }
                    const fields = Helpers.getFieldsFromSchema(databaseData.schema);
                    for (const fkField of originalDatabase[catalogCode].foreignKeyFields) {
                        const field = fields.find(f => f.schema['$id'] === fkField.schema['$id']);
                        const foreignKeys = [];
                        for (const foreignKey of fkField.schema.foreignKeys) {
                            const relatedCatalog = this.props.databaseList.find(c => c.code === foreignKey.databaseKey);
                            if (relatedCatalog) {
                                foreignKey.databaseKey = String(relatedCatalog.id);
                                foreignKeys.push(foreignKey);
                            }
                        }
                        if (foreignKeys.length > 0) {
                            field.schema.foreignKeys = foreignKeys;
                        }
                    }
                    const updatedDatabaseResponse = await this.props.registryService.modifyDatabase(databaseData).catch(reason => {
                        NotificationManager.error(Helpers.getMessageException(reason), 'Database creating failed.');
                    });
                    if (updatedDatabaseResponse) {
                        const databaseResponse = await this.props.registryService.getDatabase(databaseData.id).catch(reason => {
                            NotificationManager.error(Helpers.getMessageException(reason), 'Database');
                        });
                        if (databaseResponse) {
                            databaseCatalog.databases[0] = databaseResponse.data;
                            databaseCatalog.databases[0].catalog = databaseCatalog;
                        }
                    }
                }
            }
        }
    }

    checkDatabaseForAgainModify(databaseData) {
        const originalDatabase = {};
        const foreignKeyFields = Helpers.getForeignKeyFieldsFromSchema(databaseData.schema);
        if (foreignKeyFields.length > 0) {
            originalDatabase[databaseData.code] = {
                isDraft: databaseData.isDraft,
                foreignKeyFields: Helpers.deepCopy(foreignKeyFields)
            }
            databaseData.isDraft = true;
        }
        for (const field of foreignKeyFields) {
            delete field.schema.foreignKeys;
        }
        return originalDatabase;
    }

    async createDatabase(data) {
        data['$$mode'] = 'new';
        const createdDatabaseResponse = await this.props.registryService.modifyDatabase(data).catch(reason => {
            NotificationManager.error(Helpers.getMessageException(reason), 'Database creating failed.');
        });
        if (createdDatabaseResponse) {
            const databaseResponse = await this.props.registryService.getDatabase(createdDatabaseResponse.data.id).catch(reason => {
                NotificationManager.error(Helpers.getMessageException(reason), 'Database');
            });
            if (databaseResponse) {
                let databaseCatalog = {
                    id: createdDatabaseResponse.data.catalog_id,
                    name: data.catalog_name,
                    code: createdDatabaseResponse.data.catalog_code,
                    group_id: createdDatabaseResponse.data.group_id,
                    data_index_increment: createdDatabaseResponse.data.data_index_increment,
                    databases: []
                }
                databaseResponse.data.catalog = databaseCatalog;
                databaseCatalog.databases.push(databaseResponse.data);
                Helpers.assignDatabaseCatalogPermission(this.props.authUser, databaseCatalog);
                this.props.databaseList.push(databaseCatalog);
            }
        }
    }

    closeCollapsedNav() {
        this.props.toggleCollapsedNav(false);
    }

    renderDatabaseMenuItem(databaseCatalog, star = false) {
        if (databaseCatalog.databases.length === 0) {
            return [];
        }
        let color = '#316896';
        if (databaseCatalog.group_id) {
            const dbGroup = this.props.databaseGroups.find(g => g.id === databaseCatalog.group_id);
            if (dbGroup && dbGroup.color_hex) {
                color = dbGroup.color_hex;
            }
        }
        const sorted = databaseCatalog.databases.sort(Helpers.arrayCompareValues('version', 'DESC'));
        const latestNonDraftDatabase = sorted.filter(d => !d.is_draft).first;
        const latestDatabase = latestNonDraftDatabase ? latestNonDraftDatabase : sorted.first;
        const draggableId = `db-${databaseCatalog.id}`;
        return (
            <Draggable key={draggableId} index={this.state.databaseList.indexOf(databaseCatalog)}
                       draggableId={draggableId}>
                {(draggableProvided, draggableSnapshot) => (
                    <NavLink key={draggableId} ref={draggableProvided.innerRef}
                             to={`/app/database/${latestDatabase.id}/dashboard`}
                             className="db-link-item" onClick={this.closeCollapsedNav}
                             {...draggableProvided.draggableProps}
                             {...draggableProvided.dragHandleProps}>
                        <span className="database-list-item">
                            <span className="database-list-item-name">{databaseCatalog.name}</span>
                            <DatabaseVersionLabel database={latestDatabase} separator="" dateFormat="DD/MM/YYYY" renderList={true} />
                        </span>
                    </NavLink>
                )}
            </Draggable>
        );
    }
}


const mapStateToProps = ({database, auth, services}) => {
    const databaseList = database.list;
    const databaseEventBus = database.eventBus;
    const databaseGroups = database.groups;
    const authUser = auth.authUser;
    const authService = services.auth;
    const registryService = services.registry;
    const modalService = services.modal;
    return {databaseList, authUser, authService, databaseGroups, registryService, modalService, databaseEventBus}
};

export default injectIntl(withRouter(connect(mapStateToProps, {})(SidenavContent)));
