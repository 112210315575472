import enLang from './entries/en-US';
import zhLang from './entries/zh-Hans-CN';
import arLang from './entries/ar_SA';
import itLang from './entries/it_IT';
import esLang from './entries/es_ES';
import frLang from './entries/fr_FR';
import {addLocaleData} from 'react-intl';
import saMessages from "./locales/es_ES";
import appLocaleData from "react-intl/locale-data/es";
import 'moment'
let AppLocale = {
    en: enLang,
    // zh: zhLang,
    // ar: arLang,
    // it: itLang,
    //es: esLang,
    //fr: frLang
};

Object.keys(AppLocale).forEach(langCode => {
    addLocaleData (AppLocale[langCode].data);
    if(langCode !== 'en') {
        require(`moment/locale/${langCode}`);
    }
});

export let settedAppLocaleData = false;
export function setAppLocaleData (data) {
    Object.keys(data.languages).forEach(langCode => {
        if(!AppLocale.hasOwnProperty(langCode)) {
            let localeData = [];
            try {
                localeData = require(`react-intl/locale-data/${langCode}`)
            }
            catch (e) {}
            AppLocale[langCode] = {
                messages: {},
                locale: langCode,
                data: localeData
            };
            if(langCode !== 'en') {
                try {
                    require(`moment/locale/${langCode}`);
                }
                catch (e) {}
            }
            addLocaleData(AppLocale[langCode].data);
            AppLocale[langCode]['name'] = data.languages[langCode];
        }
    });
    Object.keys(data.messages).forEach((langCode => {
        const messages = AppLocale[langCode].messages;
        const updateMessages = data.messages[langCode];
        AppLocale[langCode].messages = {...messages, ...updateMessages}
    }));
    const langCodes = Object.keys(AppLocale);
    langCodes.forEach(langCode => {
        const translationLangId = `translation.lang.${langCode}`;
        langCodes.forEach(langCode1 => {
            if (!AppLocale[langCode1].messages.hasOwnProperty(translationLangId)) {
                AppLocale[langCode1].messages[translationLangId] = AppLocale[langCode].name;
            }
        })
    });
    settedAppLocaleData = true;
}

export default AppLocale;
