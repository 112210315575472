import React from 'react';
import {connect} from 'react-redux';
import {hideMessage, showAuthLoader, userSignIn,} from 'actions/Auth';
import * as QueryString from "query-string"
import {Alert, ListGroup, ListGroupItem} from "reactstrap";
import IntlMessages from "appUtil/IntlMessages";
import Helpers from "../../ts/utils/Helpers";

class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.retryLogin = this.retryLogin.bind(this);
        this.state = {
            authList: this.props.authList.filter(a => a.login_enabled),
            loading: true,
            notifications: []
        };
        this.props.notificationService.getMessages().then(response => {
            this.setState({
                loading: false,
                notifications: response.data
            });
            if(this.state.authList.length === 1 && response.data.length === 0) {
                this.redirectOAuth(this.state.authList.first);
            }
        });
        const query = this.props.location.search ? QueryString.parse(this.props.location.search) : null;
        this.backUrl = (query !== null && query['backUrl']) ? query['backUrl'] : null;
    }

    componentDidMount() {
        if (this.state.authList.length === 1 && this.state.loading && this.state.notifications.length > 0) {
            this.redirectOAuth(this.state.authList.first);
        }
    }

    redirectOAuth(auth) {
        this.setState({loading: true});
        this.props.authService.getLoginUrl(this.backUrl, auth.id, this.props.currentLocale).then(response => {
            window.location = response.data.url;
        }).catch(reason => {
            this.setState({
                loading: false,
                notifications: [{level: 'error', message: Helpers.getMessageException(reason)}]
            })
        })
    }

    componentDidUpdate() {
        if (this.props.showMessage) {
            setTimeout(() => {
                this.props.hideMessage();
            }, 100);
        }
        if (this.props.authUser !== null) {
            this.props.history.push('/');
            window.location.reload(true);
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="loader">
                    <svg className="circular" viewBox="25 25 50 50">
                        <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2"
                                strokeMiterlimit="10"/>
                    </svg>
                </div>
            );
        }

        return (
            <div className="login-content">
                {this.state.notifications.map((notification, notificationIndex) => (
                    <Alert key={notificationIndex} color={this.getAlertColor(notification.level)}>
                        {notification.message}
                    </Alert>
                ))}
                {this.state.authList.length > 1 && <div>
                    <h1 className="login-header"><IntlMessages id="login.header"/></h1>
                    <ListGroup>
                        {this.state.authList.map((auth, authIndex) =>
                            <ListGroupItem key={authIndex} tag="button" action
                                           onClick={this.redirectOAuth.bind(this, auth)}>
                                {auth.name}
                            </ListGroupItem>
                        )}
                    </ListGroup>
                </div>}
                {this.state.authList.length === 1 && this.state.notifications.length > 0 && <button type="button" className="btn btn-primary"
                        onClick={this.retryLogin}><IntlMessages id="login.retry"/></button>}

            </div>
        );
    }

    retryLogin() {
        this.redirectOAuth(this.state.authList.first);
    }

    getAlertColor(level) {
        if(level === 'error'){
            return 'danger';
        }
        else if(level === 'warning'){
            return 'warning';
        }
        else if(level === 'info'){
            return 'info';
        }
        return 'primary';
    }
}

const mapStateToProps = ({auth, services, settings}) => {
    const {locale: {locale: currentLocale}} = settings;
    const authService = services.auth;
    const notificationService = services.notification;
    const authList = auth.list;
    const {loader, alertMessage, showMessage, authUser} = auth;
    return {loader, alertMessage, showMessage, authUser, authService, authList, notificationService, currentLocale}
};

export default connect(mapStateToProps, {
    userSignIn,
    hideMessage,
    showAuthLoader
})(SignIn);
