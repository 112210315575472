import {Route, Switch} from "react-router-dom";
import PrivateRoute from "components/PrivateRoute";
import React from "react";

const SwitchRoutes = ({routes, path}) => {
    const basePath = path || '';

    return (
        <Switch>
            {routes.map((route, routeIndex) => (
                route.public ? <Route key={routeIndex} path={`${basePath}${route.path}`} component={route.component}/> :
                    <PrivateRoute key={routeIndex} requiredRoles={route.requiredRoles} path={`${basePath}${route.path}`}
                                  component={route.component}/>
            ))}
        </Switch>
    );
};

export default SwitchRoutes;