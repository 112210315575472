import React, {Component} from "react";
import * as PropTypes from "prop-types";
import ToggleButton from "components/ToggleButton";
import {injectIntl} from "react-intl";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import Accordion from 'react-bootstrap/Accordion';
import cn from "classnames";
import Helpers from "../../../ts/utils/Helpers";
import Notification from "appUtil/Notification";
import IntlMessages from "appUtil/IntlMessages";

const portal = document.createElement('div');
portal.classList.add('logo-settings-element-drag-drop');
document.body.appendChild(portal);


class DatabaseLogoSettings extends Component {

    static propTypes = {
        data: PropTypes.object.isRequired,
        metaData: PropTypes.object.isRequired,
        onChange: PropTypes.func,
        onChangeMetadata: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.handleDragEnd = this.handleDragEnd.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.state = {
            data: props.data,
            metaData: {...this.props.metaData},
            openedPanelKeys: [],
        }
    }

    render() {
        const {data, openedPanelKeys} = this.state;
        let settingsLabel = {};
        Object.keys(data.visible).forEach(key => settingsLabel[key] = this.props.intl.formatMessage({id: `database.logo.settings.visible.${key}`}));
        return (
            <div className="db-heading-settings">
                <DragDropContext onDragEnd={this.handleDragEnd}>
                    <Accordion alwaysOpen activeKey={openedPanelKeys}>
                        <Droppable droppableId={`logo-element`} type={'logo-element'}>
                            {(droppableProvided, droppableSnapshot) => (
                                <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                                    {data.orderElements.map((elementKey, elementIndex) => (
                                        <Draggable key={elementKey} index={elementIndex}
                                                    draggableId={elementKey}>
                                            {(draggableProvided, draggableSnapshot) => {
                                                const acBody = this.renderBody(elementKey);
                                                const dragItem = acBody ? <Accordion.Item ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} eventKey={elementKey} className="page-panel">
                                                        <div className={cn("d-flex align-items-center justify-content-between page-panel-heading", {collapsed: !openedPanelKeys.includes(elementKey)})} onClick={this.toggleCollapsePanel.bind(this, elementKey)} {...draggableProvided.dragHandleProps}>
                                                            <div className="panel-heading-title">{settingsLabel[elementKey]}</div>
                                                            <div className="d-flex column-gap" style={{ "--column-gap": "12px" }}>
                                                                <ToggleButton checked={data.visible[elementKey]}
                                                                                onChange={this.toggleVisible.bind(this, elementKey)}/>
                                                                <i className={cn("fa-solid", {"fa-chevron-up translate-y": openedPanelKeys.includes(elementKey), "fa-chevron-down": !openedPanelKeys.includes(elementKey)})} style={{ "--translate-y-value": "2px" }} />
                                                            </div>
                                                        </div>
                                                        <Accordion.Body className="page-panel-body">
                                                            {acBody}
                                                        </Accordion.Body>
                                                </Accordion.Item> : <div className="page-panel">
                                                    <div className="d-flex align-items-center justify-content-between page-panel-heading" ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps}>
                                                        <div className="panel-heading-title">{settingsLabel[elementKey]}</div>
                                                        <div className="d-flex column-gap" style={{ "--column-gap": "12px" }}>
                                                            <ToggleButton checked={data.visible[elementKey]}
                                                                            onChange={this.toggleVisible.bind(this, elementKey)}/>
                                                        </div>
                                                    </div>
                                                </div>;
                                                return draggableSnapshot.isDragging ? ReactDOM.createPortal(dragItem, portal) : dragItem;
                                            }}
                                        </Draggable>
                                    ))}
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Accordion>
                </DragDropContext>
            </div>
        );
    }

    renderBody(elementKey) {
        if (elementKey === 'databaseLogo') {
            return (
                <div className="form-group flex-one">
                    {this.state.metaData.databaseLogoUrl && <div className="display-contents">
                        <img className="database-logo" src={this.state.metaData.databaseLogoUrl} alt=""/>
                        <button className="system-button size-s minimal trash-button icon-button ml-1">
                            <i className="far fa-times remove-image button-icon"
                               onClick={this.removeLogoImg.bind(this, 'databaseLogoUrl')}/>
                        </button>
                    </div>}
                    <button className="system-button size-s outline upload-button"
                            type="button" onClick={this.uploadLogoImg.bind(this, 'databaseLogoUrl')}>
                        <IntlMessages id={this.state.metaData.databaseLogoUrl ? "common.change" : "common.upload"}/>
                    </button>
                </div>
            )
        } else if (elementKey === 'groupTitle') {
            return (
                <div className="form-group flex-one">
                    <div className="system-input-container size-s value-m">
                        <input name="groupName" className="form-control form-control-sm" type="text" value={this.state.metaData.groupName} onChange={this.onChange} onBlur={this.onBlur}/>
                    </div>
                </div>
            )
        } else if (elementKey === 'groupLogo') {
            return (
                <div className="form-group flex-one">
                    {this.state.metaData.groupLogoUrl && <div className="display-contents">
                        <img className="database-logo" src={this.state.metaData.groupLogoUrl} alt=""/>
                        <button className="system-button size-s minimal trash-button icon-button ml-1">
                            <i className="far fa-times remove-image button-icon"
                               onClick={this.removeLogoImg.bind(this, 'groupLogoUrl')}/>
                        </button>
                    </div>}
                    <button className="system-button size-s outline upload-button"
                            type="button" onClick={this.uploadLogoImg.bind(this, 'groupLogoUrl')}>
                        <IntlMessages id={this.state.metaData.groupLogoUrl ? "common.change" : "common.upload"}/>
                    </button>
                </div>
            )
        } else if (elementKey === 'databaseInstitution') {
            return (
                <div className="form-group flex-one">
                    <div className="textarea system-input-container">
                        <textarea name="databaseInstitution" value={this.state.metaData.databaseInstitution} onChange={this.onChange}/>
                    </div>
                </div>
            )
        }
        return '';
    }

    onChange(e) {
        const metaData = {...this.state.metaData};
        metaData[e.target.name] = e.target.value;
        this.setState({metaData});
        if (this.props.onChangeMetadata) {
            this.props.onChangeMetadata(metaData);
        }
    }

    onBlur(e) {
        const metaData = {...this.state.metaData};
        if (!metaData[e.target.name] && this.props.metaData[e.target.name] !== metaData[e.target.name]) {
            metaData[e.target.name] = this.props.metaData[e.target.name];
            if (this.props.onChangeMetadata) {
                this.props.onChangeMetadata(metaData);
            }
            this.setState({metaData});
        }

    }

    toggleCollapsePanel(key) {
        const openedPanelKeys = [...this.state.openedPanelKeys];
        const index = openedPanelKeys.indexOf(key);
        if (index === -1) {
            openedPanelKeys.push(key);
        }
        else {
            openedPanelKeys.splice(index, 1);
        }
        this.setState({openedPanelKeys});
    }

    handleDragEnd(result) {
        if (!result.destination || !result.source || result.destination.index === result.source.index) {
            return false;
        }
        let data = {...this.state.data}
        let orderElements = [...data.orderElements];
        const [moved] = orderElements.splice(result.source.index, 1);
        orderElements.splice(result.destination.index, 0, moved);
        data.orderElements = orderElements;
        this.setState({
            data
        });
        if (this.props.onChange) {
            this.props.onChange(data);
        }
    }

    toggleVisible(key, enabled) {
        let data = {...this.state.data};
        data.visible[key] = enabled;
        this.setState({data});
        if (this.props.onChange) {
            this.props.onChange(data);
        }
    }

    removeLogoImg(propKey) {
        const metaData = {...this.state.metaData};
        metaData[propKey] = '';
        this.setState({metaData});
        if (this.props.onChangeMetadata) {
            this.props.onChangeMetadata(metaData);
        }
    }

    uploadLogoImg(propKey) {
        const uploadLogoFileInput = document.createElement('input');
        uploadLogoFileInput.setAttribute('type', 'file');
        uploadLogoFileInput.setAttribute('accept', '.jpg,.png,.gif,.svg');
        uploadLogoFileInput.onchange = (event) => {
            if (event.target.files.length > 0) {
                const file = event.target.files[0];
                if (!Helpers.checkUploadFileSizeLimit(file)) {
                    Notification.uploadFileLimitError(file.size);
                    return;
                }
                let reader = new FileReader();
                reader.onload = (e) => {
                    const metaData = {...this.state.metaData};
                    metaData[propKey] = e.target.result;
                    metaData[`${propKey}Filename`] = file.name;
                    metaData[`${propKey}Mimetype`] = file.type;
                    this.setState({metaData});
                    if (this.props.onChangeMetadata) {
                        this.props.onChangeMetadata(metaData);
                    }
                };
                reader.readAsDataURL(file);
            }
        }
        uploadLogoFileInput.click();
    }
}

export default injectIntl(DatabaseLogoSettings)