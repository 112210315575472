export default class ModalService {
    private modal = null;

    public registerModal(modal: any) {
        this.modal = modal;
        this.close = this.close.bind(this);
    }

    show(header, body, footer, className = '', keepOpened = false, type = 'modal', modalProps = null) {
        if (!this.modal) {
            return;
        }
        this.modal.show(header, body, footer, className, keepOpened, type, modalProps);
    }

    replace(header, body, footer, className = '', keepOpened = false, type = 'modal', modalProps = null) {
        if (!this.modal) {
            return;
        }
        this.modal.replace(header, body, footer, className, keepOpened, type, modalProps);
    }

    close() {
        if (!this.modal) {
            return;
        }
        this.modal.close();
    }
}