import {NotificationManager} from "react-notifications";
import React from "react";
import IntlMessages from "appUtil/IntlMessages";
import Helpers from "../../ts/utils/Helpers";

export default class Notification {
    static error(title, message) {

        NotificationManager.error(<div>{typeof (message) === 'string' ? message.split("\n").map((line, lineIndex) => <p
            key={lineIndex}>{line}</p>) : message} </div>, title);
    }

    static uploadFileLimitError(currentFileSize) {
        NotificationManager.error(<IntlMessages id="file.upload.limit.error.reason" values={{
            sizeLimit: Helpers.convertSize(UPLOAD_FILE_SIZE_LIMIT),
            currentFileSize: Helpers.convertSize(currentFileSize)
        }}/>, <IntlMessages id="file.upload.limit.error.title"/>);
    }

}