import React from 'react';
import RouterBase from "app/routes/RouterBase";

export default class LogPages extends RouterBase {

    get className() {
        return 'app-wrapper';
    }

    get routes() {
        return [
            {
                path: '',
                asyncComponent: () => import('./dashboard'),
                requiredRoles: ['database-read']
            }
        ]
    }
}