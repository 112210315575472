import axios, {AxiosInstance, AxiosRequestConfig} from 'axios'
import EventBus from "./EventBus";
import Helpers from "./Helpers";
import {EventSourceMessage, getBytes, getLines, getMessages} from "@microsoft/fetch-event-source/lib/cjs/parse";


class ApiClient {

    protected config: AxiosRequestConfig;
    protected errorHandler: (reason: any) => void;
    protected instance: AxiosInstance;
    protected eventBus: EventBus;

    constructor(baseUrl: string, errorHandler?: (reason: any) => void, updatedAuthorization?: (value: string) => void) {
        this.config = {
            baseURL: baseUrl
        };
        this.errorHandler = errorHandler;
        this.instance = axios.create();
        const eventBus = new EventBus();
        this.eventBus = eventBus;
        this.instance.interceptors.response.use((response) => {
            if (response.headers.authorization) {
                const willCallback = updatedAuthorization && !this.config.headers || this.config.headers.Authorization !== response.headers.authorization
                this.setAuthorization(response.headers.authorization);
                if (willCallback) {
                    updatedAuthorization(response.headers.authorization);
                }
            }
            return response
        }, async function (error) {
            if (error && error.response) {
                if (error.response.status === 401) {
                    eventBus.emit("response-unauthorized");
                }
            }
            return Promise.reject(error);
        });
    }

    public registerEvent(eventName: string, callback: () => void) {
        this.eventBus.addListener(eventName, callback);
    }

    public unregisterEvent(eventName, callback) {
        this.eventBus.removeListener(eventName, callback);
    }

    public setAuthorization(token?: string) {
        if (!this.config.headers) {
            this.config.headers = {};
        }
        if (token == null && this.config.headers.hasOwnProperty('Authorization')) {
            delete this.config.headers.Authorization;
        } else {
            this.config.headers.Authorization = token;
        }
        this.eventBus.emit("updated-auth-token");
    }

    public setCsrfToken(token?: string) {
        if (!this.config.headers) {
            this.config.headers = {};
        }
        if (token == null && this.config.headers.hasOwnProperty('X-CSRFToken')) {
            delete this.config.headers['X-CSRFToken'];
        } else {
            this.config.headers['X-CSRFToken'] = token;
        }
        this.eventBus.emit("updated-csrf-token");
    }

    public getAuthorizationToken() {
        return this.config.headers && this.config.headers.Authorization ? this.config.headers.Authorization : null;
    }

    private onError(reason: any) {
        if (this.errorHandler != null) {
            this.errorHandler(reason);
        }
    }

    public get(endpoint: string) {
        return this.instance.get(endpoint, this.config);
    }

    public post(endpoint: string, data: object) {
        return this.instance.post(endpoint, data, this.config);
    }

    public put(endpoint: string, data: object, headers?: any) {
        let config = this.config;
        if (headers) {
            config = Helpers.deepCopy(config);
            config.headers = {...(config.headers || {}), ...headers};
        }
        return this.instance.put(endpoint, data, config);
    }

    public delete(endpoint: string) {
        return this.instance.delete(endpoint, this.config);
    }

    private async fetchEventSource(endpoint: string, method: string, onMessage: (e: EventSourceMessage) => void) {
        const ctrl = new AbortController();
        const headers = {
            Accept: "application/json, text/event-stream",
            ...this.config.headers
        };
        try {
            const response = await fetch(endpoint, {
                method,
                headers,
                signal: ctrl.signal
            });
            if (response.ok && response.status === 200) {

            } else if (
                response.status >= 400 &&
                response.status < 500 &&
                response.status !== 429
            ) {
                try {
                    // @ts-ignore
                    response.errorResponseData = await response.json();
                } catch (ex) {
                    // @ts-ignore
                    response.errorResponseData = await response.text();
                }
                throw response;
            }
            const LastEventId = 'last-event-id';
            await getBytes(response.body, getLines(getMessages(id => {
                if (id) {
                    headers[LastEventId] = id;
                } else {
                    delete headers[LastEventId];
                }
            }, retry => {
            }, onMessage)))
        } finally {
            ctrl.abort();
        }
    }

    public async deleteWithEventSource(endpoint: string, onMessage: (e: EventSourceMessage) => void) {
        return this.fetchEventSource(`${this.config.baseURL}${endpoint}`, 'DELETE', onMessage);
    }
}

export default ApiClient;