import BaseService from "./BaseService";
import {stringify} from "query-string";
import {EventSourceMessage} from "@microsoft/fetch-event-source/lib/cjs/parse";


export default class RegistryService extends BaseService {
    public getDatabases() {
        return this.client.get('/databases');
    }

    public getDatabase(id: number) {
        return this.client.get(`/database/${id}`);
    }

    public editDatabase(id: number) {
        return this.client.post(`/api/v1/database/${id}/edit`, {});
    }


    public duplicateDatabase(id: number, copyData) {
        return this.client.post(`/api/v1/database/${id}/duplicate`, {copyData});
    }

    public getDataList(code: string, version: string, page: number = 1, search: string = null, ordering: string = null, page_size: number = null, filter: string = null) {
        if (version == null) {
            version = 'any';
        }
        let url = `/api/v1/data/${code.toLowerCase()}/${version}`;
        let query = {};
        query['page'] = page;
        if (search) {
            query['search'] = search;
        }
        if (ordering) {
            query['ordering'] = ordering;
        }
        if (page_size) {
            query['page_size'] = page_size;
        }
        if (filter) {
            query['filter'] = filter;
        }
        if (query) {
            url += '?' + stringify(query);
        }
        return this.client.get(url);
    }

    public getData(id: number) {
        return this.client.get(`api/v1/data-detail/${id}`);
    }

    public searchField(code: string, version: string, q: string, field: string, field_id: string, limit: number) {
        if (version == null) {
            version = 'any';
        }
        let url = `/api/v1/data/${code.toLowerCase()}/${version}/search-field`;
        let query = {q};
        if (field) {
            query['field'] = field;
        }
        if (field_id) {
            query['field_id'] = field_id;
        }
        if (limit) {
            query['limit'] = limit;
        }
        url += '?' + stringify(query);
        return this.client.get(url);
    }

    public getDataByRegistryNumber(code: string, version: number, registryNumber: any) {
        return this.client.get(`api/v1/data/${code}/${version}/${registryNumber}`);
    }

    public modifyDatabase(data) {
        return this.client.post('/api/v1/database/modify', data);
    }

    public deleteDatabase(databaseId: number) {
        return this.client.delete(`/api/v1/database/${databaseId}`);
    }

    public deleteService(id: number) {
        return this.client.delete(`/api/v1/service/${id}`);
    }

    public deleteDatabaseGenericService(databaseId: number, serviceId: number) {
        return this.client.delete(`/api/v1/database/${databaseId}/generic-service/${serviceId}`);
    }

    public deleteAllDatabaseGenericService(databaseId: number) {
        return this.client.delete(`/api/v1/database/${databaseId}/generic-services`);
    }

    public deleteDatabaseCatalog(databaseCatalogId: number) {
        return this.client.delete(`/api/v1/database-catalog/${databaseCatalogId}`);
    }

    public getServices() {
        return this.client.get('/services');
    }

    public getService(id) {
        return this.client.get(`/services/${id}`);
    }

    public modifyService(data) {
        return this.client.post('/api/v1/service/modify', data);
    }

    public modifyData(data: any) {
        if (data.hasOwnProperty('id')) {
            return this.client.put('/api/v1/data', data);
        }
        return this.client.post('/api/v1/data', data);
    }

    public deleteData(id: number) {
        return this.client.delete(`/api/v1/data/${id}`);
    }

    public deleteAllData(databaseId: number, filter: string = null, search: string = null, ids: string = null, onMessage: (e: EventSourceMessage) => void) {
        let query: any = {};
        if (filter) {
            query['filter'] = filter;
        }
        if (search) {
            query['search'] = search;
        }
        if (ids) {
            query['ids'] = ids;
        }
        let url = `api/v1/database/${databaseId}/data/delete`;
        if (query) {
            url += '?' + stringify(query);
        }
        if (onMessage) {
            return this.client.deleteWithEventSource(url, onMessage);
        }
        return this.client.delete(`/${url}`);
    }

    public getDocument(documentId: number) {
        return this.client.get(`/api/v1/document/${documentId}`);
    }

    public getDataMigrations(databaseId: number) {
        return this.client.get(`/api/v1/database/${databaseId}/migration`);
    }

    public testDataMigration(databaseId: number, fromDatabaseId: number, template: string, data: any) {
        return this.client.post(`/api/v1/database/${databaseId}/migration/test`, {
            data,
            template,
            from_database_id: fromDatabaseId
        });
    }

    public modifyDataMigration(databaseId: number, data) {
        return this.client.post(`/api/v1/database/${databaseId}/migration/modify`, data);
    }

    public runDataMigration(databaseId: number) {
        return this.client.post(`/api/v1/database/${databaseId}/migration/run`, {});
    }

    public getExportStatus(databaseId: number) {
        return this.client.get(`/api/v1/database/${databaseId}/export/status`);
    }

    public runExportData(databaseId: number, filename: string) {
        return this.client.post(`/api/v1/database/${databaseId}/export/run`, {
            filename
        });
    }

    public getImportStatus(databaseId: number) {
        return this.client.get(`/api/v1/database/${databaseId}/import/status`);
    }

    public runImportData(databaseId: number, fileId: string, options: any) {
        return this.client.post(`/api/v1/database/${databaseId}/import/run`, {
            file_id: fileId,
            options
        });
    }

    public setGenericServiceVisible(databaseId: number, serviceId: number, isVisible: boolean) {
        return this.client.put(`/api/v1/database/${databaseId}/generic-service/${serviceId}/visible`, {
            value: isVisible
        });
    }

    public setAllGenericServicesVisible(databaseId: number, isVisible: boolean) {
        return this.client.put(`/api/v1/database/${databaseId}/generic-services/visible`, {
            value: isVisible
        });
    }

    public setServiceVisible(id: number, isVisible: boolean) {
        return this.client.put(`/api/v1/service/${id}/visible`, {
            value: isVisible
        });
    }

    public downloadSynchronizedData(id: number) {
        return this.client.get(`/api/v1/database/synchronization/${id}/download`);
    }

    public getDataFilterHash(databaseId, data: any) {
        return this.client.post(`/api/v1/database/${databaseId}/data-filter`, data);
    }

    public getDatabaseReports(databaseId) {
        return this.client.get(`/api/v1/database/${databaseId}/reports`);
    }

    public getDatabaseReport(databaseId, reportId) {
        return this.client.get(`/api/v1/database/${databaseId}/reports/${reportId}`);
    }

    public modifyDatabaseReport(databaseId: number, data: any) {
        return this.client.post(`/api/v1/database/${databaseId}/report`, data);
    }

    public getTags() {
        return this.client.get(`/api/v1/tags`);
    }

    public moveReportOrder(databaseId: number, reportId: number, index: number) {
        return this.client.post(`/api/v1/database/${databaseId}/report/${reportId}/move`, {index});
    }

    public deleteDatabaseReport(databaseId: number, reportId: number) {
        return this.client.delete(`/api/v1/database/${databaseId}/report/${reportId}/delete`);
    }

    public getReportPdf(databaseId: number, reportId: number, orderFields: Array<any> = null) {
        let query = {};
        if (orderFields && orderFields.length > 0) {
            query['orderFields'] = JSON.stringify(orderFields);
        }
        return this.client.get(`/database/${databaseId}/report/${reportId}?` + stringify(query));
    }

    public exportReportData(databaseId: number, reportId: number, extension: string, syncId: number = null, orderByFields: Array<any> = null) {
        let data = {extension};
        if (syncId) {
            data['id'] = syncId;
        }
        if (orderByFields && orderByFields.length > 0) {
            data['orderFields'] = orderByFields;
        }
        return this.client.post(`/database/${databaseId}/report/${reportId}/export`, data);
    }

    public getDatabaseGroups() {
        return this.client.get(`/api/v1/database-groups`);
    }

    public moveDatabaseCatalogOrder(databaseCatalogId: number, index: number, groupId = null) {
        let data: any = {
            index,
            'group_id': groupId
        };
        return this.client.post(`/api/v1/database-catalog/${databaseCatalogId}/move`, data);
    }

    public moveDatabaseGroupOrder(id: number, index: number) {
        let data: any = {
            index
        };
        return this.client.post(`/api/v1/database-group/${id}/move`, data);
    }

    public modifyDatabaseGroup(data) {
        return this.client.post(`/api/v1/database-group/modify`, data);
    }

    public deleteDatabaseGroup(id: number) {
        return this.client.delete(`/api/v1/database-group/${id}/delete`);
    }



    public getDatabaseFilters() {
        return this.client.get('/api/v1/database-filters');
    }

    public modifyDatabaseFilter(data: any) {
        return data.id ? this.updateDatabaseFilter(data) : this.createDatabaseFilter(data);
    }

    public createDatabaseFilter(data: any) {
        return this.client.post('/api/v1/database-filters', data);
    }

    public updateDatabaseFilter(data: any) {
        return this.client.put(`/api/v1/database-filters/${data.id}`, data);
    }

    public deleteDatabaseFilter(id: number) {
        return this.client.delete(`/api/v1/database-filters/${id}`);
    }

    public getDataViews(databaseId) {
        return this.client.get(`/api/v1/database/${databaseId}/data/views`);
    }

    public modifyDataView(databaseId, data) {
        return this.client.post(`/api/v1/database/${databaseId}/data/view/modify`, data);
    }

    public deleteDataView(databaseId, dataViewId, deleteCatalog = null) {
        let url = `/api/v1/database/${databaseId}/data/view/${dataViewId}/delete`;
        if (deleteCatalog) {
            url += '?deleteCatalog=true';
        }
        return this.client.delete(url);
    }

    public getDataViewDetail(databaseCode, databaseVersion, dataViewCatalogId, version = 'latest', fkFieldIndex = null) {
        let url = `/data-view/${databaseCode.toLowerCase()}/${databaseVersion}/${dataViewCatalogId}/${version}`;
        if (fkFieldIndex !== null) {
            url += `/fk/${fkFieldIndex}`
        }
        url += '/detail';
        return this.client.get(url);
    }

    public getDataViewDataList(databaseCode, databaseVersion, dataViewId, page: number = 1, search: string = null, ordering: string = null, page_size: number = null, filterQuery: string = null, relationDataViewId = null, relationFieldIndex = null, relationValue = null) {
        let url = `/data-view/${databaseCode.toLowerCase()}/${databaseVersion}/${dataViewId}`;
        let query = {
            page
        };
        if (search) {
            query['search'] = search;
        }
        if (ordering) {
            query['ordering'] = ordering;
        }
        if (page_size) {
            query['page_size'] = page_size;
        }
        if (relationDataViewId && relationFieldIndex !== null && relationValue !== null) {
            query[`relation_${relationDataViewId}_${relationFieldIndex}`] = relationValue;
        }
        url += '?' + stringify(query);
        if (filterQuery) {
            url += `&${filterQuery}`;
        }
        return this.client.get(url);
    }

    public getDataViewDataFile(databaseCode, databaseVersion, dataViewId, fileId) {
        return this.client.get(`/data-view/${databaseCode}/${databaseVersion}/${dataViewId}/file/${fileId}`);
    }

    public exportDataView(databaseCode, databaseVersion, dataViewId, extension, syncId = null, relation = null, orderByFields: Array<any> = null, hiddenFieldIds: Array<any> = null, dataFilters: Array<any> = null) {
        let data = {extension};
        if (syncId) {
            data['id'] = syncId;
        } else {
            if (relation) {
                data['relation'] = relation;
            }
            if (orderByFields && orderByFields.length > 0) {
                data['orderFields'] = orderByFields;
            }
            if (hiddenFieldIds && hiddenFieldIds.length > 0) {
                data['hiddenFieldIds'] = hiddenFieldIds;
            }
            if (dataFilters && dataFilters.length > 0) {
                data['dataFilters'] = dataFilters;
            }
        }
        return this.client.post(`/data-view/${databaseCode.toLowerCase()}/${databaseVersion}/${dataViewId}/export`, data);
    }

    public exportData(databaseCode: string, databaseVersion: number, extension: string, syncId: number = null, removeFieldIds: Array<number> = null, dataFilters: Array<any> = null, orderByFields: Array<any> = null, useFieldIdAsKey) {
        let data = {extension};
        if (syncId) {
            data['id'] = syncId;
        } else {
            if (removeFieldIds && removeFieldIds.length > 0) {
                data['removeFieldIds'] = removeFieldIds;
            }
            if (dataFilters && dataFilters.length > 0) {
                data['dataFilters'] = dataFilters;
            }
            if (orderByFields && orderByFields.length > 0) {
                data['orderFields'] = orderByFields;
            }
            if (useFieldIdAsKey) {
                data['useFieldIdAsKey'] = true;
            }
        }
        return this.client.post(`/api/v1/data/${databaseCode.toLowerCase()}/${databaseVersion}/export`, data);
    }

    public resetDatabaseCatalogIndex(databaseCatalogId: number, index: number) {
        return this.client.put(`/api/v1/database-catalog/${databaseCatalogId}/reset-index`, {index});
    }

    public resetDatabaseIndex(databaseId: number, index: number) {
        return this.client.put(`/api/v1/database/${databaseId}/reset-index`, {index});
    }

    public getDatabaseIndex(databaseId: number, indexId: number) {
        return this.client.get(`/api/v1/database/${databaseId}/index/${indexId}`);
    }

    public resetDatabaseIndexV2(databaseId: number, indexId: number, data: any) {
        return this.client.put(`/api/v1/database/${databaseId}/index/${indexId}/reset`, data);
    }

    public getDatabaseLogo(databaseId: number) {
        return this.client.get(`database/${databaseId}/logo`);
    }

    public updateDatabaseLogo(databaseId: number, data: any) {
        if (data) {
            return this.client.put(`database/${databaseId}/logo`, data);
        }
        else {
            return this.client.delete(`database/${databaseId}/logo`);
        }
    }

    public updateDatabase(databaseId: number, data: any) {
        return this.client.put(`database/${databaseId}`, data);
    }

    public getDatabaseGroupLogo(groupId: number) {
        return this.client.get(`/database/group/${groupId}/logo`);
    }

    public readValue(code: string, version: number, uuid: string, field, ext = 'json') {
        let endpoint = `/data/${code}/${version}/${uuid}/read-value`;
        if (field) {
            endpoint += `/${field}`
        }
        endpoint += `.${ext}`;
        return this.client.get(endpoint);
    }

    public getFieldMetadata(code: string, version: number, uuid: string, field: string) {
        let endpoint = `/data/${code}/${version}/${uuid}/metadata`;
        if (field) {
            endpoint += `/${field}`
        }
        return this.client.get(endpoint);
    }

    public getDatabaseCatalogSettings(catalogId: number, key: string) {
        return this.client.get(`/api/v1/database-catalog/${catalogId}/settings/${key}`);
    }

    public saveDatabaseCatalogSettings(catalogId: number, key: string, data: any) {
        return this.client.post(`/api/v1/database-catalog/${catalogId}/settings/${key}`, data);
    }

    public getDatabaseCatalogGroupSettings(groupId: number, key: string) {
        return this.client.get(`/api/v1/database-group/${groupId}/settings/${key}`);
    }

    public saveDatabaseCatalogGroupSettings(groupId: number, key: string, data: any) {
        return this.client.post(`/api/v1/database-group/${groupId}/settings/${key}`, data);
    }

    public getSyncTriggers(catalogId: number) {
        return this.client.get(`/api/v1/database-catalog/${catalogId}/sync-triggers`);
    }

    public modifySyncTrigger(catalogId: number, triggerData: any) {
        if (triggerData.id) {
            return this.client.put(`/api/v1/database-catalog/${catalogId}/sync-triggers/${triggerData.id}`, triggerData);
        }
        return this.client.post(`/api/v1/database-catalog/${catalogId}/sync-triggers`, triggerData);
    }

    public getSyncDataList(databaseId: number) {
        return this.client.get(`/api/v1/database/${databaseId}/sync-data`);
    }

    public createSyncData(databaseId: number, data: any) {
        return this.client.post(`/api/v1/database/${databaseId}/sync-data`, data);
    }

    public deleteSyncTrigger(catalogId: number, triggerData: any) {
        return this.client.delete(`/api/v1/database-catalog/${catalogId}/sync-triggers/${triggerData.id}`);
    }

    public moveDataViewOrder(databaseId: number, viewId: number, index: number) {
        return this.client.put(`/api/v1/database/${databaseId}/data/view/${viewId}/move`, {index});
    }
}