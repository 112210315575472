Object.defineProperties(Array.prototype, {
    first: {
        get: function () {
            if (this.length > 0) {
                return this[0];
            }
            return null;
        }
    },
    last: {
        get: function () {
            if (this.length > 0) {
                return this[this.length - 1];
            }
            return null;
        }
    }
});


Object.defineProperty(Array.prototype, 'unique', {
    enumerable: false,
    configurable: false,
    writable: false,
    value: function () {
        let a = this.concat();
        for (let i = 0; i < a.length; ++i) {
            for (let j = i + 1; j < a.length; ++j) {
                if (a[i] === a[j])
                    a.splice(j--, 1);
            }
        }

        return a;
    }
});

Array.prototype.previous = function (item) {
    var target = this;
    var previousIndex = target.indexOf(item) - 1;

    return previousIndex < 0 ? null : target[previousIndex]
};

Array.prototype.next = function (item) {
    var target = this;
    var index = target.indexOf(item) + 1;
    if (index === -1) {
        return null;
    }
    var nextIndex = target.indexOf(item) + 1;
    return nextIndex < target.length ? target[nextIndex] : null;
};

String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};