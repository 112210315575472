import BaseService from "./BaseService";
import {stringify} from "query-string";

export default class StatisticService extends BaseService {
    public getDatabaseService(databaseId: number, serviceId: number, query: any) {
        let url = `/statistics/database/${databaseId}/service/${serviceId}`;
        if (query) {
            url += '?' + stringify(query);
        }
        return this.client.get(url);
    }

    public getDatabaseCatalogService(databaseCatalogId: number, serviceId: number, query: any) {
        let url = `/statistics/database-catalog/${databaseCatalogId}/service/${serviceId}`;
        if (query) {
            url += '?' + stringify(query);
        }
        return this.client.get(url);
    }
}