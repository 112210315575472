import React from 'react';
import {MINI_DRAWER} from "./../../constants/ActionTypes";
import InstanceLogo from "components/InstanceLogo";

const SidenavLogo = ({drawerType, onClickClose}) => {

    const showMini = drawerType.includes(MINI_DRAWER);

    return (
        <div className="sidebar-header modal-header d-flex align-items-center">
            <div className={showMini ? 'mini-logo w-100 d-flex align-items-center' : 'w-100 d-flex align-items-center'}>
                <InstanceLogo editable={true} />
                {onClickClose && <button className="system-button size-s icon-size-xl minimal grayscale close-databases-drawer">
                    <i onClick={onClickClose} className="far fa-times button-icon"/>
                </button>}
            </div>
        </div>
    );
};


export default SidenavLogo;
