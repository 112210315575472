import EventBus from "../../ts/utils/EventBus";

const INIT_STATE = {
    list: [],
    data: {},
    services: [],
    groups: [],
    eventBus: new EventBus()
};

export default (state = INIT_STATE, action) => {
    return state
}
