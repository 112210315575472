import React from 'react';
import RouterBase from "app/routes/RouterBase";


export default class ProfilePages extends RouterBase {

    get className() {
        return 'app-wrapper';
    }

    get routes() {
        return [
            {
                path: '',
                asyncComponent: () => import('./Profile')
            }
        ]
    }
}