import {Component} from "react";
import {Route, withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import asyncComponent from "appUtil/asyncComponent";
import React from "react";
import Helpers from "../../../ts/utils/Helpers";
import * as QueryString from "query-string";
import {Redirect} from "react-router-dom";

class PrivateRoute extends Component{

    render() {
        const {authUser, requiredRoles, location} = this.props;
        if(!authUser) {
            return (
                <Redirect
                    to={{
                        pathname: '/signin',
                        search: QueryString.stringify({
                            backUrl: window.location.href
                        }),
                        state: {from: location}
                    }}
                />
            )
        }
        if(authUser && (!requiredRoles || Helpers.userHasRoles(authUser, ['super-admin', ...requiredRoles]))) {
            return (
                <Route {...this.props}/>
            );
        }
        return (
            <Route {...this.props} component={asyncComponent(() => import('./AccessDenied'))}/>
        )
    }
}

const mapStateToProps = ({auth}) => {
    const authUser = auth.authUser;
    return {authUser};
};

export default withRouter(connect(mapStateToProps)(PrivateRoute));