import BaseService from "./BaseService";
import Helpers from "../utils/Helpers";
import {stringify} from "query-string";

export default class AuthService extends BaseService {

    public login(usernameOrEmail: string, password: string) {
        if (Helpers.validateEmail(usernameOrEmail)) {
            return this.client.post('/api/v1/auth/login/', {email: usernameOrEmail, password: password});
        }
        return this.client.post('/api/v1/auth/login/', {username: usernameOrEmail, password: password});
    }

    public logOut() {
        return this.client.post('/auth/logout', {});
    }

    public setAuthorization(token?: string) {
        this.client.setAuthorization(token);
    }

    public getUser() {
        return this.client.get('/auth/user');
    }

    public updateUser(username: string, firstName: string, lastName: string) {
        return this.client.put('/api/v1/auth/user/', {username: username, first_name: firstName, last_name: lastName});
    }

    public changePassword(newPassword, confirmPassword) {
        return this.client.post('/api/v1/auth/password/change/', {
            new_password1: newPassword,
            new_password2: confirmPassword
        });
    }

    public getLoginUrl(redirectTo = null, authId?: number, lang?: string) {
        let url = '/auth/login/url';
        let query = {};
        if (redirectTo) {
            query['redirectTo'] = redirectTo;
        }
        if(authId) {
            query['authId'] = authId;
        }
        if(lang) {
            query['lang'] = lang;
        }
        if(Object.keys(query).length > 0) {
            url += '?' + stringify(query)
        }
        return this.client.get(url);
    }

    public saveSettings(settings) {
        return this.client.post('/auth/settings', settings);
    }

    public getAuthList() {
        return this.client.get('/auth/list');
    }
}