import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {SIGNIN_USER, SIGNOUT_USER} from './../constants/ActionTypes';
import {showAuthMessage, userSignInSuccess, userSignOutSuccess} from 'actions/Auth';

import AuthService from "../../ts/services/AuthService";
import apiClient from "../data/apiClient";

const authService = new AuthService(apiClient);

const signInUserWithEmailPasswordRequest = async (email, password) =>
    await authService.login(email, password)
        .then(authUser => authUser)
        .catch(error => error);

const signOutRequest = async () =>
    await authService.logOut()
        .then(response => response)
        .catch(error => error);

function* signInUserWithEmailPassword({payload}) {
    const {email, password} = payload;
    try {
        const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
        if (signInUser.message) {
            let message = signInUser.message;
            if (signInUser.response.data.hasOwnProperty('non_field_errors')) {
                message = '';
                for (const errorMessage of signInUser.response.data.non_field_errors) {
                    message += errorMessage + "\n";
                }
            }
            yield put(showAuthMessage(message));
        } else {
            localStorage.setItem('user_id', signInUser.data.key);
            yield put(userSignInSuccess({payload: signInUser.data}));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signOut() {
    try {
        const signOutUser = yield call(signOutRequest);
        if (signOutUser.message) {
            yield put(showAuthMessage(signInUser.message));
        } else {
            authService.setAuthorization(null);
            localStorage.removeItem('user_id');
            if (signOutUser.data.url) {
                window.location = signOutUser.data.url;
            } else {
                yield put(userSignOutSuccess(signOutUser));
            }
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
    yield all([fork(signInUser),
        fork(signOutUser)]);
}