import BaseService from "./BaseService";
import {stringify} from "query-string";

export default class LoggerService extends BaseService {

    public getAuditLog(id: number) {
        return this.client.get(`/logger/audit/${id}`);
    }

    public getAuditLogs(page, page_size, ordering = null, dataTypeId = null, dataId = null, actionId = null, idIn = null, parentDataId = null) {
        let url = '/logger/audit';
        let query = {};
        query['page'] = page;
        if (page_size) {
            query['page_size'] = page_size;
        }
        if (ordering) {
            query['ordering'] = ordering;
        }
        if (dataTypeId) {
            query['data_type_id'] = dataTypeId;
        }
        if (dataTypeId) {
            query['data_id'] = dataId;
        }
        if (dataTypeId) {
            query['action_id'] = actionId;
        }
        if (idIn) {
            query['id_in'] = idIn;
        }
        if (parentDataId) {
            query['parent_data_id'] = parentDataId;
        }
        if (query) {
            url += '?' + stringify(query);
            return this.client.get(url);
        }
    }

    public getActions() {
        return this.client.get(`/logger/action`);
    }

    public getDataTypes() {
        return this.client.get(`/logger/data-type`);
    }

    public getFieldTypes() {
        return this.client.get(`/logger/field-type`);
    }

    public getAuditFieldLogs(page, page_size, ordering, dataTypeId = null, dataId = null, actionId = null, parentDataId = null, filterQuery = null, search = null) {
        let url = '/logger/audit/field';
        let query = {};
        query['page'] = page;
        if (page_size) {
            query['page_size'] = page_size;
        }
        if (ordering) {
            query['ordering'] = ordering;
        }
        if (parentDataId) {
            query['parent_data_id'] = parentDataId;
        }
        if (dataTypeId) {
            query['data_type_id'] = dataTypeId;
        }
        if (dataTypeId) {
            query['data_id'] = dataId;
        }
        if (search) {
            query['search'] = search;
        }
        if (query) {
            url += '?' + stringify(query);
        }
        if (filterQuery) {
            url += `${query ? '&' : '?'}${filterQuery}`;
        }
        return this.client.get(url);
    }
}