import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import IntlMessages from "appUtil/IntlMessages";
import Tooltip from "components/Tooltip";
import Helpers from "../../../ts/utils/Helpers";
import classnames from 'classnames';
import Notification from "appUtil/Notification";

class InstanceLogo extends Component {

    constructor(props) {
        super(props);
        this.uploadLogo = this.uploadLogo.bind(this);
        this.removeLogo = this.removeLogo.bind(this);
        this.instanceLogoChanged = this.instanceLogoChanged.bind(this);
        this.state = {
            logo: null
        };
        if (props.settings.data && props.settings.data.logo_image) {
            this.state.logo = {
                url: `data:${props.settings.data['logo_image_mime_type']};base64,${props.settings.data['logo_image']}`
            }
        }
        this.superAdmin = Helpers.userHasRoles(props.authUser, ['super-admin']);
    }

    componentDidMount() {
        this.uploadLogoFileInput = document.createElement('input');
        this.uploadLogoFileInput.setAttribute('type', 'file');
        this.uploadLogoFileInput.setAttribute('accept', '.jpg,.png,.gif,.svg');
        this.uploadLogoFileInput.onchange = (event) => {
            if (event.target.files.length > 0) {
                const file = event.target.files[0];
                if (!Helpers.checkUploadFileSizeLimit(file)) {
                    Notification.uploadFileLimitError(file.size);
                    return;
                }
                let newFile = {
                    type: file.type,
                    filename: file.name
                };
                let reader = new FileReader();
                reader.onload = (e) => {
                    newFile.url = e.target.result;
                    this.props.genericService.modifySettings({logo: newFile}).then(response => {
                        this.props.settings.data = response.data;
                        this.setState({
                            logo: newFile
                        });
                        this.props.settings.eventBus.emit('instance-logo-changed', newFile);
                    })
                };
                reader.readAsDataURL(file);
            }
        }
        this.props.settings.eventBus.addListener('instance-logo-changed', this.instanceLogoChanged);
    }

    instanceLogoChanged(data) {
        this.setState({logo: data});
    }

    componentWillUnmount() {
        this.props.settings.eventBus.removeListener('instance-logo-changed', this.instanceLogoChanged);
    }

    render() {
        const {editable} = this.props;
        return (
            <div className={classnames({"has-logo": this.state.logo}, "instance-logo-container flex-auto")}>
                {this.renderLogo()}
                {this.superAdmin && editable &&
                    <i className="fas fa-cog config-instance-logo" onClick={this.uploadLogo}>
                        <Tooltip placement="right"><IntlMessages id="instance.upload.logo"/></Tooltip>
                    </i>}
                {this.superAdmin && editable &&
                    <i className="far fa-times remove-instance-logo" onClick={this.removeLogo}/>}
            </div>
        );
    }

    removeLogo() {
        this.props.genericService.modifySettings({logo: null}).then(response => {
            this.props.settings.data = response.data;
            this.setState({
                logo: null
            });
            this.props.settings.eventBus.emit('instance-logo-changed', null);
        })
    }

    renderLogo() {
        if (this.state.logo) {
            return (
                <img className="instance-logo" src={this.state.logo.url} alt=""/>
            );
        }
        return (
            <i className="fad fa-database default-instance-logo"/>
        );
    }

    uploadLogo() {
        this.uploadLogoFileInput.click();
    }
}

const mapStateToProps = ({auth, services, settings}) => {
    const authUser = auth.authUser;
    const modalService = services.modal;
    const genericService = services.generic;
    return {genericService, settings, authUser, modalService};
};
export default connect(mapStateToProps)(InstanceLogo);