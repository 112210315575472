import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import SidenavContent from './SidenavContent';
import SidenavLogo from 'components/SidenavLogo';
import {toggleCollapsedNav, updateWindowWidth} from 'actions/Setting';
import cn from 'classnames';
import DrawerModal from "components/modals/DrawerModal";

class SideNav extends React.PureComponent {

    onToggleCollapsedNav = (e) => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };

    constructor(props) {
        super(props);
        this.state = {
            databaseGroupOpened: false
        }
        this.onOpenDatabaseGroup = this.onOpenDatabaseGroup.bind(this);
        this.onCloseDatabaseGroup = this.onCloseDatabaseGroup.bind(this);
        this.drawerModalRef = this.drawerModalRef.bind(this);
        this.drawerModal = null;
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.props.updateWindowWidth(window.innerWidth)
        });
    }

    render() {
        const {navCollapsed, drawerType} = this.props;
        return (
            <DrawerModal refX={this.drawerModalRef} isOpen={navCollapsed} toggle={this.onToggleCollapsedNav} className={cn("app-sidebar layer", {"group-opened": this.state.databaseGroupOpened})}
                         sizable={true}
                         width={"300px"}
                         align={"left"}>
                <div className="side-nav">
                    <SidenavLogo drawerType={drawerType} onClickClose={this.props.toggleCollapsedNav.bind(this, false)}/>
                    <SidenavContent toggleCollapsedNav={this.props.toggleCollapsedNav}
                                onOpenDatabaseGroup={this.onOpenDatabaseGroup}
                                onCloseDatabaseGroup={this.onCloseDatabaseGroup}/>
                </div>
            </DrawerModal>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.databaseGroupOpened !== this.state.databaseGroupOpened) {
            const currentWidth = this.drawerModal.getWidth();
            if(currentWidth !== null) {
                this.drawerModal.setWidth(this.state.databaseGroupOpened ? currentWidth * 2 : currentWidth / 2);
            }
        }
    }

    drawerModalRef(e) {
        this.drawerModal = e;
    }

    onOpenDatabaseGroup() {
        if (!this.state.databaseGroupOpened) {
            this.setState({databaseGroupOpened: true});
        }
    }

    onCloseDatabaseGroup() {
        if (this.state.databaseGroupOpened) {
            this.setState({databaseGroupOpened: false});
        }
    }
}

const mapStateToProps = ({settings}) => {
    const {navCollapsed, drawerType, width, isDirectionRTL, navigationStyle} = settings;
    return {navCollapsed, drawerType, width, isDirectionRTL, navigationStyle}
};

export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, updateWindowWidth})(SideNav));
