import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import Settings from './Settings';
import Auth from './Auth';
import Services from "./Services";
import Database from "./Database";


const reducers = combineReducers ({
    routing: routerReducer,
    settings: Settings,
    auth: Auth,
    database: Database,
    services: Services
});

export default reducers;
