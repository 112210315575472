import React, {Component} from "react";
import IntlMessages from "appUtil/IntlMessages";
import * as PropTypes from "prop-types";
import Helpers from "../../../ts/utils/Helpers";
import Notification from "appUtil/Notification";

export default class DatabaseGroupModify extends Component {

    static propTypes = {
        data: PropTypes.object,
        onChange: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = this.initState(props);
        this.onChange = this.onChange.bind(this);
        this.uploadImageFileClick = this.uploadImageFileClick.bind(this);
        this.removeLogo = this.removeLogo.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.data !== this.props.data) {
            this.setState(this.initState(nextProps));
        }
    }

    initState(props) {
        this.databaseGroupLogo = null;
        let data = props.data ? Helpers.deepCopy(props.data) : {name: ''};
        if (!data.color_hex) {
            data.color_hex = '#002b8f';
        }
        if (data.has_logo) {
            this.databaseGroupLogo = {
                url: `database/group/${data.id}/logo`
            };
            delete data.logo_image;
            delete data.logo_image_mime_type;
        }

        return {
            data
        }
    }

    componentDidMount() {
        this.uploadLogoFileInput = document.createElement('input');
        this.uploadLogoFileInput.setAttribute('type', 'file');
        this.uploadLogoFileInput.setAttribute('accept', '.jpg,.png,.gif,.svg');
        this.uploadLogoFileInput.onchange = (event) => {
            if (event.target.files.length > 0) {
                const file = event.target.files[0];
                if (!Helpers.checkUploadFileSizeLimit(file)) {
                    Notification.uploadFileLimitError(file.size);
                    return;
                }
                let newFile = {
                    type: file.type,
                    filename: file.name
                };
                let reader = new FileReader();
                reader.onload = (e) => {
                    newFile.url = e.target.result;
                    let {data} = this.state;
                    this.databaseGroupLogo = newFile;
                    data.logo = newFile;
                    this.setState({data});
                    if (this.props.onChange) {
                        this.props.onChange(data);
                    }
                };
                reader.readAsDataURL(file);
            }
        }
    }

    render() {
        const {data} = this.state;
        return (
            <div className="d-flex column-gap" style={{ "--column-gap": "12px" }}>
                <div className="form-group flex-one">
                    <label className="system-label"><IntlMessages id="database.group.name"/></label>
                    <div className="system-input-container size-s value-m">
                        <input name="name" className="form-control form-control-sm" type="text" value={data.name}
                            onChange={this.onChange}/>
                    </div>
                </div>
                <div className="form-group">
                    <label className="system-label"><IntlMessages id="database.group.color"/></label>
                    <div className="system-input-container size-s value-m">
                        <input name="color_hex" className="color-field" type="color"
                            value={data.color_hex} style={{ "--color-value": data.color_hex }}
                            onChange={this.onChange}/>
                    </div>
                </div>
                <div className="form-group flex-one">
                    <label className="upload-label system-label"><IntlMessages id="database.group.logo"/></label>
                    {this.databaseGroupLogo && <div className="display-contents">
                    <img className="database-logo" src={this.databaseGroupLogo.url} alt=""/>
                    <button className="system-button size-s minimal trash-button icon-button ml-1">
                        <i className="far fa-times remove-image button-icon" onClick={this.removeLogo}/>
                    </button> </div>}
                    <button className="system-button size-s outline upload-button"
                            type="button" onClick={this.uploadImageFileClick}>
                            <IntlMessages id={this.databaseGroupLogo ? "common.change" : "common.upload"}/>
                    </button>
                </div>
            </div>
        )
    }

    removeLogo() {
        let {data} = this.state;
        data.logo = null;
        this.databaseGroupLogo = null;
        this.setState({data});
        if (this.props.onChange) {
            this.props.onChange(data);
        }
    }

    uploadImageFileClick(event) {
        this.uploadLogoFileInput.click(event);
    }

    onChange(event) {
        let {data} = this.state;
        data[event.target.name] = event.target.value;
        this.setState({data});
        if (this.props.onChange) {
            this.props.onChange(data);
        }
    }

}