import React from 'react';
import asyncComponent from '../../../util/asyncComponent';
import RouterBase from "app/routes/RouterBase";


export default class DiagramPages extends RouterBase {

    get className() {
        return 'app-diagram-wrapper';
    }

    get routes() {
        return [
            {
                path: '/database',
                component: asyncComponent(() => import('./database')),
                requiredRoles: ['database-read']
            }
        ]
    }
}