import React from 'react';
import {DropdownItem} from "reactstrap";
import IntlMessages from "appUtil/IntlMessages";

const LanguageItem = ({language, switchLanguage, handleRequestClose}) => {
    return (
        <DropdownItem onClick={() => {
            handleRequestClose();
            switchLanguage(language);
        }}>
            <IntlMessages id={`translation.lang.${language.locale}`} defaultMessage={language.name}/>
        </DropdownItem>
    );
};

export default LanguageItem;
