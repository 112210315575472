import * as React from "react";
import connect from "react-redux/es/connect/connect";
// @ts-ignore
import moment from "moment";
import {injectIntl} from "react-intl";

class DatabaseVersionLabel extends React.Component<{ database: any, authList?: any, intl?: any, separator: any, dateFormat: any, renderList: false }, {}> {

    private readonly byOnBehalfOfUserMessage: string;

    constructor(props) {
        super(props);
        this.byOnBehalfOfUserMessage = this.props.intl.formatMessage({id: 'database.by_on-behalf-of-user'});
    }

    render() {
        const {database, separator, dateFormat, renderList} = this.props;
        const sepStr = separator || '';
        const dateFormatStr = dateFormat || 'DD/MM/YYYY - HH:mm';
        const dateTimeString = moment(database.modified_at).format(dateFormatStr);
        let byUser = database.by_user_name ? ` ${database.by_user_name}` : '';
        if (byUser !== '' && this.props.authList.length > 1) {
            const authId = database.by_user_auth_id ? database.by_user_auth_id : 0;
            const auth = this.props.authList.filter(a => a.id === authId).first;
            if (auth) {
                byUser += ` (${auth.name})`;
            }
        }
        if (byUser !== '' && database.by_on_behalf_of_user_name) {
            byUser = `${byUser} ${this.byOnBehalfOfUserMessage} ${database.by_on_behalf_of_user_name}`
            const authId = database.by_on_behalf_of_user_auth_id ? database.by_on_behalf_of_user_auth_id : 0;
            const auth = this.props.authList.filter(a => a.id === authId).first;
            if (auth) {
                byUser += ` (${auth.name})`;
            }
        }
        if (renderList) {
            const draftLabel = database.is_draft ? this.props.intl.formatMessage({id: 'draft'}) : '';
            return (<span className="display-content">
                <span className="system-badge">{database.version} {draftLabel}</span>
                <span className="version-date-user-container">
                    <span className="version-date">
                        <i className="far fa-calendar" />
                        <span className="ellipsis">{dateTimeString}</span>
                    </span>
                    <span className="version-user">
                        <i className="far fa-user" />
                        <span className="ellipsis">{byUser}</span>
                    </span>
                </span>
            </span>);
        }
        if (database.is_draft) {
            return `${database.version}${this.props.intl.formatMessage({id: 'draft'})}${dateTimeString}${byUser}`;
        }
        return (
            <span className="database-version-label">
                <span>{database.version}</span>
                {separator && <span className="version-label-separator">
                    {sepStr}
                </span>}
                <span className="smaller">{dateTimeString}</span>
                {separator && <span className="version-label-separator">
                    {sepStr}
                </span>}
                <span>{byUser}</span>
            </span>
        );
    }
}

const mapStateToProps = ({auth}) => {
    const authUser = auth.authUser;
    const authList = auth.list;
    return {
        authUser,
        authList
    };
};
export default injectIntl(connect(mapStateToProps)(DatabaseVersionLabel));